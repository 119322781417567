import React, { useContext, useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Button,
  IconButton,
  Typography,
  Popover,
  Checkbox,
  Card,
  Box,
  FormControlLabel,
  CardContent,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";
import SettingsIcon from "@mui/icons-material/Settings";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { CircleAlert } from "lucide-react";
import { ClipboardPenLine } from "lucide-react";
import { FormContext } from '../../context/FormContext';

const FormReviewer = ({ color, fontColor }) => {
  const { formReviewerData, handleFormReviewerChange, documentColor } = useContext(FormContext);
  const [rowsPage1, setRowsPage1] = useState([]);

  useEffect(() => {
    if (formReviewerData && formReviewerData.rows_page1) {
      setRowsPage1(formReviewerData.rows_page1);
    } else {
      // Initialize with three empty rows if no data
      setRowsPage1([
        { reviewed_by: "", company: "", print_name: "", signature: "", date: "" },
        { reviewed_by: "", company: "", print_name: "", signature: "", date: "" },
        { reviewed_by: "", company: "", print_name: "", signature: "", date: "" },
      ]);
    }
  }, [formReviewerData]);

  const handleRowChange = (index, field, value) => {
    const updatedRows = [...rowsPage1];
    updatedRows[index] = { ...updatedRows[index], [field]: value };
    setRowsPage1(updatedRows);
    handleFormReviewerChange({ ...formReviewerData, rows_page1: updatedRows });
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [showDeleteIcon, setShowDeleteIcon] = useState(false);
  const open = Boolean(anchorEl);
  const id = open ? "popover-anchor" : undefined;
  const [expanded, setExpanded] = useState(false); // Track accordion expansion

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleAccordionChange = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  const handleAddRowPage1 = () => {
    setRowsPage1([
      ...rowsPage1,
      { reviewed_by: "", company: "", print_name: "", signature: "", date: "" },
    ]);
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const handleDeleteRow = (table, index) => {
    if (table === "page1") {
      const updatedRows = rowsPage1.filter((_, rowIndex) => rowIndex !== index);
      setRowsPage1(updatedRows);
    }
  };

  return (
    <Card
      sx={{
        boxShadow: 3,
        border: `4px solid ${documentColor}`,
        backgroundColor: "white",
        opacity: 0.9,
      }}
    >
      <Accordion expanded={expanded} onChange={handleAccordionChange}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            backgroundColor: documentColor,
            display: "flex",
            alignItems: "center",
            height: "60px",
            "&.Mui-expanded": {
              height: "60px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignContent: "center",
              width: "100%",
            }}
          >
            <IconButton
              aria-describedby={id}
              onClick={(e) => {
                if (expanded) {
                  e.stopPropagation();
                  handlePopoverOpen(e);
                }
              }}
              sx={{
                padding: 0,
                color: fontColor,
                mr: 1, // Add margin-right to space out from the text
              }}
            >
              <ClipboardPenLine />
            </IconButton>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "Roboto, sans-serif",
                  fontWeight: "bold",
                  color: fontColor
                }}
                onClick={(e) => {
                  if (expanded) {
                    e.stopPropagation();
                    handlePopoverOpen(e);
                  }
                }}
              >
                Form Reviewer
              </Typography>
            </Box>
          </Box>
        </AccordionSummary>

        <AccordionDetails sx={{ bgcolor: "#E9EAEC", padding: 2 }}>
          <CardContent
            sx={{
              padding: 0,
              marginBottom: 0,
              "&:last-child": {
                paddingBottom: 0,
              },
            }}
          >
            <Grid container spacing={2}>
              {/* Table for Page 1 */}
              <Grid item xs={12}>
                <Box
                  sx={{
                    border: `2px solid ${documentColor}`,
                    padding: 2,
                    marginBottom: 0,
                    borderRadius: "3px",
                    position: "relative",
                  }}
                >
                  <Table>
                    <TableHead>
                      {/* <TableRow sx = {{ alignItems: 'center'}}>
                                            <TableCell colSpan = {5} sx = {{bgcolor: color, border: '1px solid black', fontWeight: 'bold', padding: 1, textAlign: 'center'}}>Form Reviewer</TableCell>
                                        </TableRow> */}
                      <TableRow sx={{ alignItems: "center" }}>
                        <TableCell
                          sx={{
                            bgcolor: documentColor,
                            border: "1px solid black",
                            fontWeight: "bold",
                            padding: 1,
                            textAlign: "center",
                          }}
                        >
                          Position
                        </TableCell>
                        <TableCell
                          sx={{
                            bgcolor: documentColor,
                            border: "1px solid black",
                            fontWeight: "bold",
                            padding: 1,
                            textAlign: "center",
                          }}
                        >
                          Company
                        </TableCell>
                        <TableCell
                          sx={{
                            bgcolor: documentColor,
                            border: "1px solid black",
                            fontWeight: "bold",
                            padding: 1,
                            textAlign: "center",
                          }}
                        >
                          Print Name
                        </TableCell>
                        <TableCell
                          sx={{
                            bgcolor: documentColor,
                            border: "1px solid black",
                            fontWeight: "bold",
                            padding: 1,
                            textAlign: "center",
                          }}
                        >
                          Signature
                        </TableCell>
                        <TableCell
                          sx={{
                            bgcolor: documentColor,
                            border: "1px solid black",
                            fontWeight: "bold",
                            padding: 1,
                            textAlign: "center",
                          }}
                        >
                          Date
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rowsPage1.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell
                            sx={{ border: "1px solid black", padding: 1 }}
                          >
                            <TextField
                              value={row.reviewed_by}
                              size="small"
                              onChange={(e) =>
                                handleRowChange(index, 'reviewed_by', e.target.value)
                              }
                              fullWidth
                              sx={{
                                bgcolor: "white",
                                fontFamily: "Roboto, sans-serif",
                                borderRadius: "5px",
                              }}
                              placeholder="Position"
                            />
                          </TableCell>
                          <TableCell
                            sx={{ border: "1px solid black", padding: 1 }}
                          >
                            <TextField
                              value={row.company}
                              size="small"
                              onChange={(e) =>
                                handleRowChange(index, 'company', e.target.value)
                              }
                              fullWidth
                              sx={{
                                bgcolor: "white",
                                fontFamily: "Roboto, sans-serif",
                                borderRadius: "5px",
                              }}
                              placeholder="company"
                            />
                          </TableCell>
                          <TableCell
                            sx={{ border: "1px solid black", padding: 1 }}
                          >
                            <TextField
                              value={row.print_name}
                              size="small"
                              onChange={(e) =>
                                handleRowChange(index, 'print_name', e.target.value)
                              }
                              fullWidth
                              sx={{
                                bgcolor: "white",
                                fontFamily: "Roboto, sans-serif",
                                borderRadius: "5px",
                              }}
                              placeholder="Print name"
                            />
                          </TableCell>
                          <TableCell
                            sx={{ border: "1px solid black", padding: 1 }}
                          >
                            <TextField
                              value={row.signature}
                              size="small"
                              onChange={(e) =>
                                handleRowChange(index, 'signature', e.target.value)
                              }
                              fullWidth
                              sx={{
                                bgcolor: "white",
                                fontFamily: "Roboto, sans-serif",
                                borderRadius: "5px",
                              }}
                              placeholder="Signature"
                            />
                          </TableCell>
                          <TableCell
                            sx={{ border: "1px solid black", padding: 1 }}
                          >
                            <TextField
                              value={row.date}
                              size="small"
                              name="date"
                              type="date"
                              onChange={(e) =>
                                handleRowChange(index, 'date', e.target.value)
                              }
                              fullWidth
                              sx={{
                                bgcolor: "white",
                                fontFamily: "Roboto, sans-serif",
                                borderRadius: "5px",
                              }}
                              placeholder="Date"
                            />
                          </TableCell>
                          {showDeleteIcon && (
                            <TableCell sx={{ padding: 0, width: "30px" }}>
                              <IconButton
                                onClick={() => handleDeleteRow("page1", index)}
                                style={{ color: "red", fontSize: "0.8rem" }}
                              >
                                <CancelIcon fontSize="small" />
                              </IconButton>
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </AccordionDetails>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
            <IconButton
              aria-label="close"
              onClick={handlePopoverClose}
              size="small"
              sx={{
                position: "absolute",
                top: 8,
                right: 8,
                color: "grey.500",
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showDeleteIcon}
                  onChange={() => setShowDeleteIcon(!showDeleteIcon)}
                  sx={{
                    color: documentColor,
                    "&.Mui-checked": {
                      color: documentColor,
                    },
                    "& .MuiCheckbox-root": {
                      color: documentColor,
                      "&.Mui-checked": {
                        color: documentColor,
                      },
                    },
                  }}
                />
              }
              label="Delete Rows"
            />
            <Button
              onClick={handleAddRowPage1}
              startIcon={<AddIcon />}
              variant="contained"
              sx={{
                bgcolor: documentColor,
                fontFamily: "Roboto, sans-serif",
                color: "black",
                borderRadius: "3px",
                textTransform: "none",
                boxShadow: 3,
                "&:hover": {
                  boxShadow: 4,
                },
                "&:active": {
                  // background: 'linear-gradient(150deg, #6096B4 0%, #000 100%)',
                  boxShadow: 2,
                },
              }}
            >
              Add Row to Page 1
            </Button>
          </Box>
        </Popover>
      </Accordion>
    </Card>
  );
};

export default FormReviewer;
