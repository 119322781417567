import React, { useState, useContext } from "react"
import { Dialog, DialogTitle, DialogContent, DialogActions, Box, Button, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom"
import { FormContext } from "../context/FormContext"
import Background from "../assets/Background.jpg";

export default function ProjectDetailsCard() {
  const navigate = useNavigate()
  const {
    documentColor,
    setDocumentColor,
    ProjectDetailsData,
    setProjectDetailsData,
    saveFormData,
    swmsAutocompleteAppVisibility,
    setSwmsAutocompleteAppVisibility,
    openColorDialog,
    setOpenColorDialog
  } = useContext(FormContext)

  const [loading, setLoading] = useState(false)
  const [percentage, setPercentage] = useState(0)

  const handleNextClick = () => {
    // if(!ProjectDetailsData) {
    //   return;
    // }else{
    // }
    setSwmsAutocompleteAppVisibility(false);
    setOpenColorDialog(true);
  }

  const handleClose = () => {
    navigate("/user-dashboard")
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setProjectDetailsData((prev) => ({ ...prev, [name]: value }))
  }

  const handleSaveDocument = async () => {
    // Implement the save logic here
    await saveFormData({
      ...ProjectDetailsData,
      documentColor
    })
  }

  return (
    <div
      className="min-h-screen flex flex-col items-center justify-center p-4"
      style={{
        backgroundImage:`url(${Background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="absolute text-center mb-2 mt-4 max-w-2xl top-[0]">
        <h1 className="text-2xl font-bold text-white">START BY ENTERING YOUR PROJECT DETAILS</h1>
        <p className="text-white text-sm">
          This information is to keep your files organised. Besides being displayed on your SWMS document it will also
          be shown on the cards in your dashboard.
        </p>
      </div>

      <div className="bg-white w-full max-w-2xl mt-[70px] rounded-sm shadow-lg">
        <div className="py-3 px-6 bg-[#FFA500] flex rounded-t-sm items-center gap-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
            <polyline points="14 2 14 8 20 8" />
          </svg>
          <h2 className="text-xl font-bold">Project Details</h2>
        </div>

        <div className="p-6 space-y-3">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm text-gray-600 mb-1">SWMS Title</label>
              <input
                name="swmsTitle"
                value={ProjectDetailsData.swmsTitle}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border rounded-md"
                placeholder="Enter SWMS title"
              />
            </div>
            <div>
              <label className="block text-sm text-gray-600 mb-1">SWMS No</label>
              <input
                name="swmsNo"
                value={ProjectDetailsData.swmsNo}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border rounded-md"
                placeholder="Enter SWMS number"
              />
            </div>
            <div>
              <label className="block text-sm text-gray-600 mb-1">Work Location</label>
              <input
                name="workLocation"
                value={ProjectDetailsData.workLocation}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border rounded-md"
                placeholder="Enter work location"
              />
            </div>
            <div>
              <label className="block text-sm text-gray-600 mb-1">Rev No</label>
              <input
                name="revNo"
                value={ProjectDetailsData.revNo}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border rounded-md"
                placeholder="Enter revision number"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm text-gray-600 mb-1">Document Title</label>
            <input
              name="documentTitle"
              value={ProjectDetailsData.documentTitle}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded-md"
              placeholder="Enter document title"
            />
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm text-gray-600 mb-1">Project Name</label>
              <input
                name="projectName"
                value={ProjectDetailsData.projectName}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border rounded-md"
                placeholder="Enter project name"
              />
            </div>
            <div>
              <label className="block text-sm text-gray-600 mb-1">Date</label>
              <input
                type="date"
                name="date"
                value={ProjectDetailsData.date}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border rounded-md"
              />
            </div>
            <div>
              <label className="block text-sm text-gray-600 mb-1">Contractor Name</label>
              <input
                name="contractorName"
                value={ProjectDetailsData.contractorName}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border rounded-md"
                placeholder="Enter contractor name"
              />
            </div>
            <div>
              <label className="block text-sm text-gray-600 mb-1">ABN</label>
              <input
                name="abn"
                value={ProjectDetailsData.abn}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border rounded-md"
                placeholder="Enter ABN"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm text-gray-600">Address</label>
            <input
              name="address"
              value={ProjectDetailsData.address}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded-md"
              placeholder="Enter address"
            />
          </div>
        </div>

        <div className="flex justify-end gap-4 py-1 px-6 bg-gray-50 rounded-b-lg">
          <button
            onClick={handleClose}
            className="px-6 py-2 bg-[#FFA500] text-black rounded-md"
          >
            Cancel
          </button>
          <button
            onClick={handleNextClick}
            className="px-6 py-2 bg-[#FFA500] text-black rounded-md"
          >
            Next
          </button>
        </div>
      </div>
      <div className="text-white text-center text-lg mt-1">
          <p style={{opacity: 0.6}}>Icromm Pyt. Ltd.</p>
      </div>
    </div>
  )
}

