import React, { useState, useContext, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Snackbar,
  DialogActions,
  Button,
  TextField,
  IconButton,
  Box,
  CircularProgress,
  TextareaAutosize,
  Typography,
  InputLabel,
  MenuItem,
} from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import { SketchPicker } from "react-color";
import PaletteIcon from "@mui/icons-material/Palette";
import { ArrowBigLeft, Bot } from "lucide-react";
import CloseIcon from "@mui/icons-material/Close";
import { Close } from "@mui/icons-material";
import qs from "qs";
import axios from "axios";
import { lighten, darken } from "@mui/system";
import { useNavigate, useParams } from "react-router-dom";
import { FormContext } from '../../context/FormContext'; // Add this import

const ColorPickerAndApiModal = ({
  open,
  onClose,
  onColorChange,
  onFontColorChange,
  color,
  fontColor,
  loading,
  setLoading,
  setPercentage,
  handleSaveDocument,
  handleUpdateDocument,
}) => {
  const { documentColor, setDocumentColor, formMetadata, documentFontColor,
    setDocumentFontColor,
    setRiskManagementScheduleData,
    setHighLevelRisksData,
    setPersonsInvolvedCardData,
    setConstructionPlantEquipmentData,
    setLegislativeListData,
    setPPECardData,
    setPermitsData
  } = useContext(FormContext);
  const [showSketchPicker, setShowSketchPicker] = useState(false);
  const [showFontSketchPicker, setShowFontSketchPicker] = useState(false);
  const [eventSource, setEventSource] = useState(null);
  const [formValues, setFormValues] = useState({
    task: formMetadata.taskDetails || '',
    location: formMetadata.locationDetails || '',
    includeTask: formMetadata.includeTasks || '',
    excludeTask: formMetadata.excludeTasks || '',
    numberOfActivities: formMetadata.numberOfActivities || 16,
    state: formMetadata.selectedState || '',
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [min, setMin] = useState(10);
  const [max, setMax] = useState(24);

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    setFormValues({
      task: formMetadata.taskDetails || '',
      location: formMetadata.locationDetails || '',
      includeTask: formMetadata.includeTasks || '',
      excludeTask: formMetadata.excludeTasks || '',
      numberOfActivities: formMetadata.numberOfActivities || 22,
      state: formMetadata.selectedState || '',
    });
  }, [formMetadata]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleColorChange = (newColor) => {
    // Update both the local state and the context
    onColorChange(newColor.hex);
    setDocumentColor(newColor.hex);
  };

  const handleFontColorChange = (newColor) => {
    onFontColorChange(newColor.hex);
    setDocumentFontColor(newColor.hex);
  }

  const handleClose = () => {
    setShowFontSketchPicker(false);
    setShowSketchPicker(false);
    onClose();
  };

  const handleSubmit = async () => {
    if (formValues.task === "" || formValues.location === "") {
      setSnackbarMessage("Kindly fill the task and location fields");
      setSnackbarOpen(true);
      return;
    }

    if (!/^\d+$/.test(formValues.numberOfActivities)) {
      setSnackbarMessage(
        `Number of activities should be between ${min} and ${max}`
      );
      setSnackbarOpen(true);
      return;
    }

    if (
      formValues.numberOfActivities < min ||
      formValues.numberOfActivities > max
    ) {
      setSnackbarMessage(
        `Number of activities should be between ${min} and ${max}`
      );
      setSnackbarOpen(true);
      return;
    }

    if (!formValues.state) {
      setSnackbarMessage("Please select a state");
      setSnackbarOpen(true);
      return;
    }

    try {
      const params = new URLSearchParams({
        task: formValues.task,
        location: formValues.location,
        include_tasks: formValues.includeTask || '',
        exclude_tasks: formValues.excludeTask || '',
        n: formValues.numberOfActivities.toString(),
        state_AU: formValues.state || ''
      });

      const eventSource = new EventSource(
        `https://www.icromm.com/api/process_questions/?${params.toString()}`
      );

      let messageCount = 0;
      setLoading(true);
      setEventSource(eventSource);
      setPercentage(0);

      eventSource.onmessage = (event) => {
        console.log('event inside color picker', event);
        try {
          console.log("jsonData eventSource", event.data);
          const jsonData1 = JSON.parse(event.data);
          if (jsonData1.status === "complete") {
            // console.log("All messages have been received.");
            eventSource.close();
            setLoading(false);
            handleClose();
            return;
          }
          // const jsonData1 = JSON.parse(jsonData);
          // console.log('jsondata 1: ', jsonData1);

          messageCount++;
          switch (messageCount) {
            case 1:
              try {
                console.log('Json Data', jsonData1);
                const jsonData = JSON.parse(jsonData1);

                const risks = jsonData.initialRiskTable.map((item) => {

                  let formattedCodeOfPractice = item.codeOfPractice;

                  const match = formattedCodeOfPractice.match(/\[(.*?)\]\((.*?)\)/);
                  if (match) {
                    const text = match[1];
                    const url = match[2];
                    formattedCodeOfPractice = `${text}\n${url}`;
                  }

                  return {
                    number: item.number,
                    risk: `${item.risk}\n`,
                    explanation: `${item.explanation}\n`,
                    codeOfPractice: `${formattedCodeOfPractice}\n`,
                    extendedInformation: `${item.extendedInformation.map(info => `- ${info}`).join("\n")}\n`
                  };
                });

                // console.log("Parsed risks:", risks); // Debug log
                setHighLevelRisksData(risks);
                setPercentage(1);
              } catch (error) {
                console.error("Error parsing Q1 data:", error);
              }
              break;
            case 2:
              try {
                // Parse the JSON data if it's a string
                const riskData = typeof jsonData1 === 'string' ? JSON.parse(jsonData1) : jsonData1;

                console.log("riskData is: ", riskData);

                // Map the data to the expected format
                const sections = riskData.map((item, index) => {
                  // Format risks and controls
                  const formattedRisksAndControls = item.risks_and_controls.map(rc => {
                    return `Risk: ${rc.risk}\nControls:\n- ${rc.controls.join('\n- ')}`;
                  }).join('\n\n');

                  return {
                    id: index,
                    no: item.number || "",
                    activity: item.activity?.trim() || "",
                    riskDescription: `${formattedRisksAndControls}\n`,
                    initialRisk: item.initial_risk?.trim() || "",
                    hierarchy: item.hierarchy || "",
                    controlsProcedures: `${formattedRisksAndControls}\n`,
                    residualRisk: item.residual_risk?.trim() || "",
                    personsResponsible: item.persons_responsible?.join(', ') || "",
                  };
                });

                console.log("Parsed risk management data:", sections);
                setRiskManagementScheduleData(sections);
                setPercentage(2);
              } catch (error) {
                console.error("Error parsing Q2 data:", error);
              }
              break;
            case 3:
              try {
                // Parse the JSON data
                const personsData = JSON.parse(jsonData1);

                // Map the data to the expected format
                const formattedPersons = personsData.map((person, index) => ({
                  id: person.id ?? `temp-${index}`,
                  jobTitle: person.jobTitle || "",
                
                  // Remove commas after full stops in responsibilities
                  rolesResponsibilities: Array.isArray(person.rolesResponsibilities) 
                    ? person.rolesResponsibilities.map(item => item.replace(/\. *,/g, '.'))
                    : typeof person.rolesResponsibilities === 'string' 
                      ? person.rolesResponsibilities.replace(/\. *,/g, '.').split('\n').map(item => item.trim()) 
                      : [],
                
                  // Remove commas after full stops in qualifications
                  qualificationsExperience: Array.isArray(person.qualificationsExperience) 
                    ? person.qualificationsExperience.map(item => item.replace(/\. *,/g, '.'))
                    : typeof person.qualificationsExperience === 'string' 
                      ? person.qualificationsExperience.replace(/\. *,/g, '.').split('\n').map(item => item.trim()) 
                      : []
                }));

                setPersonsInvolvedCardData(formattedPersons);
                setPercentage(3);
              } catch (error) {
                console.error("Error parsing Q3 data:", error);
              }
              break;
            case 4:
              try {
                // Skip empty initial chunk
                if (!jsonData1 || jsonData1 === "[]") {
                  // console.log("Received empty initial chunk for PPE data");
                  return;
                }

                // Log raw data for debugging
                // console.log("Raw PPE data received:", jsonData1);

                // Parse the data
                const ppeData = JSON.parse(jsonData1);

                // Skip if no data
                if (!ppeData || !Array.isArray(ppeData)) {
                  // console.log("Invalid PPE data format received");
                  return;
                }

                // Format the PPE data
                const formattedPPE = ppeData.map((item, index) => ({
                  id: index,
                  activity: item.activity,
                  ppeDescription: `${item.ppeDescription}\n`,
                  australianStandards: `${item.australianStandards}\n`
                }));

                // console.log("Formatted PPE data:", formattedPPE);
                setPPECardData(formattedPPE);
                setPercentage(4);

              } catch (error) {
                if (error instanceof SyntaxError) {
                  // console.log("Received partial PPE data, waiting for complete response");
                } else {
                  console.error("Error parsing Q4 data:", error);
                  // console.log("Raw data received:", jsonData1);
                }
              }
              break;
            case 5:
              try {
                // Skip empty initial chunk
                if (!jsonData1 || jsonData1 === "[]") {
                  return;
                }

                // Parse the JSON data
                const permitsData = JSON.parse(jsonData1);

                // Skip if no data
                if (!permitsData || !Array.isArray(permitsData)) {
                  return;
                }

                // Map the data to the expected format
                const formattedPermits = permitsData.map((permit, index) => ({
                  id: index,
                  number: permit.number?.toString() || "",
                  permitName: permit.permitName || "",
                  howToAcquire: `${permit.howToAcquire?.replace(/\n/g, '\n')}\n` || "", // Ensure newlines are preserved
                  whyRequired: `${permit.whyRequired}\n` || "",
                }));

                // console.log("Formatted permits:", formattedPermits); // Debug log
                setPermitsData(formattedPermits);
                setPercentage(5);

              } catch (error) {
                console.error("Error parsing Q5 data:", error);
              }
              break;
            case 6:
              try {
                // Parse the JSON string to get the array of legislative items
                const legislativeItems = JSON.parse(jsonData1);

                // Map the items to the expected format
                const formattedItems = legislativeItems.map((item, index) => ({
                  // id: index,
                  Number: item.Number || "",
                  Activity: item.Activity || "",
                  "List the Legislative Reference and ACOPs": Array.isArray(item["List the Legislative Reference and ACOPs"])
                    ? item["List the Legislative Reference and ACOPs"]
                      .map(ref => `- ${ref.trim()}`)
                      .join("\n")
                    : typeof item["List the Legislative Reference and ACOPs"] === 'string'
                      ? item["List the Legislative Reference and ACOPs"]
                      : "",
                  "Why the legislation and ACOPs apply": Array.isArray(item["Why the legislation and ACOPs apply"])
                    ? item["Why the legislation and ACOPs apply"].join("\n")
                    : typeof item["Why the legislation and ACOPs apply"] === 'string'
                      ? item["Why the legislation and ACOPs apply"]
                      : ""
                }));

                console.log("Formatted legislative items:", formattedItems); // Debug log
                setLegislativeListData(formattedItems);
                setPercentage(6);
              } catch (error) {
                console.error("Error parsing Q6 data:", error);
              }
              break;
            case 7:
                // console.log("Raw Q7 data received:", event.data);

                // Parse the JSON data
                const cleanData = event.data.replace(/^data: /, '').trim();
                if (!cleanData || cleanData === "[]") {
                  // console.log("Empty Q7 data received");
                  return;
                }
                // console.log("cleanData", cleanData);

                let parsedData;
                try {
                  // If the data is already a string, parse it. If it's an object, use it directly
                  parsedData = typeof event.data === 'string' ? JSON.parse(cleanData) : event.data;
                  // console.log("Parsed Q7 data:", parsedData);

                  // Ensure parsedData is an array
                  if (!Array.isArray(parsedData)) {
                    // If it's a string, try parsing it again
                    try {
                      parsedData = JSON.parse(parsedData);
                    } catch (e) {
                      console.error("Could not parse data into array:", e);
                      return;
                    }
                  }

                  // Modify the safety maintenance data to include dashes
                  const formattedData = parsedData.map((item, index) => ({
                    ...item,
                    id: index,
                    maintenanceRequirements: Array.isArray(item.maintenanceRequirements)
                      ? item.maintenanceRequirements.map(maintenance =>
                        maintenance.startsWith('- ') ? maintenance : `- ${maintenance.trim()}`
                      )
                      : typeof item.maintenanceRequirements === 'string'
                        ? item.maintenanceRequirements
                        : ""
                  }));

                // Update the React state with transformed data
                console.log('formatted data of construction', formattedData);
                setConstructionPlantEquipmentData(formattedData);
                setPercentage(7);

              } catch (parseError) {
                console.error("JSON parse error:", parseError);
              }
              break;
            default:
              break;
          }
        } catch (error) {
          console.error("Error parsing event data as JSON:", error);
        }
      };

      eventSource.onerror = (error) => {
        console.error("EventSource failed:", error);
        eventSource.close();
        setLoading(false);
      };

    } catch (error) {
      console.error("Error during API call:", error);
      setLoading(false);
      setSnackbarMessage("Error processing request. Please try again.");
      setSnackbarOpen(true);
    }
  };

  const handleSaveAndGoToDashboard = async () => {
    if (typeof handleUpdateDocument === "function") {
      try {
        setLoading(true);
        await handleUpdateDocument(id, { color: documentColor, font_color: documentFontColor });
        setLoading(false);
        navigate("/user-dashboard/all-documents");
      } catch (error) {
        console.error("Error updating document:", error);
        setLoading(false);
        setSnackbarMessage("Error updating document. Please try again.");
        setSnackbarOpen(true);
      }
    } else {
      navigate("/user-dashboard/all-documents");
    }
  };

  const handleCancel = () => {
    if (eventSource) {
      eventSource.close();
      setLoading(false);
      setEventSource(null);
    }
  }

  // Add Australian states array
  const australianStates = [
    "Queensland",
    "New South Wales",
    "Victoria",
    "Western Australia",
  ];

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      sx={{ borderRadius: "3px" }}
    >
      <DialogTitle sx={{ bgcolor: color, borderRadius: "3px 3px 0 0" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <IconButton
            sx={{
              padding: 0,
              color: "black",
              mr: 1, // Add margin-right to space out from the text
            }}
          >
            <Bot />
          </IconButton>
          <Typography
            variant="h6"
            sx={{
              fontFamily: "Roboto, sans-serif",
              fontWeight: "bold",
              color: fontColor
            }}
          >
            SWMS Autocomplete App
          </Typography>
        </Box>
        <Box
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
          }}
        >
          {/* Font Sketch Picker Button with Tooltip */}
          <Tooltip title="Click to Open & Close" arrow>
            <IconButton
              sx={{ cursor: "pointer", mr: 1 }}
              onClick={() => setShowFontSketchPicker(!showFontSketchPicker)}
            >
              <Box
                sx={{
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%",
                  backgroundColor: color,
                  border: "2px solid #DDDDDD",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  "&:hover": {
                    "& .MuiSvgIcon-root": {
                      opacity: 1, // Show icon on hover
                    },
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  T
                </Typography>
              </Box>
            </IconButton>
          </Tooltip>

          {/* Color Picker Button with Tooltip */}
          <Tooltip title="Click to Open & Close" arrow>
            <IconButton sx={{ cursor: "pointer" }} onClick={() => setShowSketchPicker(!showSketchPicker)}>
              <Box
                sx={{
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%",
                  backgroundColor: color,
                  border: "2px solid #DDDDDD",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  "&:hover": {
                    "& .MuiSvgIcon-root": {
                      opacity: 1, // Show icon on hover
                    },
                  },
                }}
              >
                <PaletteIcon sx={{ fontSize: "18px", color: "black" }} />
              </Box>
            </IconButton>
          </Tooltip>

          {/* Close Button with Tooltip */}
          <Tooltip title="Close" arrow>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Tooltip>
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{
          borderLeft: `4px solid ${documentColor}`,
          borderRight: `4px solid ${documentColor}`,
          mb: 0,
          paddingBottom: 1,
          paddingLeft: 2,
          paddingRight: 2,
          bgcolor: "#E9EAEC",
        }}
      >
        <Box sx={{ mb: 2, position: "absolute", zIndex: 9, right: 10 }}>
          {showFontSketchPicker && (
            <SketchPicker color={fontColor} onChange={handleFontColorChange} disableAlpha={true} />
          )}
        </Box>
        <Box sx={{ mb: 2, position: "absolute", zIndex: 9, right: 10 }}>
          {showSketchPicker && (
            <SketchPicker
              color={color}
              onChange={handleColorChange}
              disableAlpha={true}
            />
          )}
        </Box>
        <Box
          sx={{
            border: `2px solid ${documentColor}`,
            padding: 2,
            borderRadius: "3px",
            mb: 0,
            mt: 2,
            position: "relative",
          }}
        >
          <form>
            <InputLabel
              htmlFor={`task`}
              sx={{
                fontSize: "12px",
                fontWeight: "bold",
                color: "#424242",
                fontFamily: "Roboto, sans-serif",
              }}
            >
              Task Details
            </InputLabel>
            <TextareaAutosize
              id={`task`}
              name="task"
              value={formValues.task}
              placeholder="Providing a comprehensive and detailed scope of work here ensures a more accurate and tailored Safe Work Method Statement (SWMS) document, reflecting the specific risks and controls relevant to the task you want to cover in this SWMS document."
              onChange={handleFormChange}
              minRows={3}
              style={{
                width: "100%",
                padding: "8px",
                marginBottom: "8px",
                borderRadius: "5px",
                borderColor: "grey",
                fontFamily: "Roboto, sans-serif",
              }}
            />
            <InputLabel
              htmlFor={`location`}
              sx={{
                fontSize: "12px",
                fontWeight: "bold",
                color: "#424242",
                fontFamily: "Roboto, sans-serif",
              }}
            >
              Location and Site Details
            </InputLabel>
            <TextareaAutosize
              id={`location`}
              name="location"
              value={formValues.location}
              onChange={handleFormChange}
              placeholder="Provide specific and detailed information about the site location and any relevant environmental factors or restrictions. Include descriptions of methods, plants, and work details unique to the site. Any special considerations, like using specific machinery or safety protocols, should be clearly outlined. The more precise the details, the more tailored the safety measures can be."
              minRows={3}
              style={{
                width: "100%",
                padding: "8px",
                marginBottom: "8px",
                borderRadius: "5px",
                borderColor: "grey",
                fontFamily: "Roboto, sans-serif",
              }}
            />
            <InputLabel
              htmlFor={`includeTask`}
              sx={{
                fontSize: "12px",
                fontWeight: "bold",
                color: "#424242",
                fontFamily: "Roboto, sans-serif",
              }}
            >
              Must Include Tasks (Optional)
            </InputLabel>
            <TextareaAutosize
              id={`includeTask`}
              name="includeTask"
              value={formValues.includeTask}
              onChange={handleFormChange}
              placeholder="All construction sites are unique, so it’s important to list the essential tasks that must be included in your SWMS to address your project's specific needs. Be specific about activities you want listed or compliance procedures that are critical for this job. For example, tasks might include special operational procedures, specific protocols, or safety measures for hazardous activities."
              minRows={3}
              style={{
                width: "100%",
                padding: "8px",
                marginBottom: "8px",
                borderRadius: "5px",
                borderColor: "grey",
                fontFamily: "Roboto, sans-serif",
              }}
            />
            <InputLabel
              htmlFor={`excludeTask`}
              sx={{
                fontSize: "12px",
                fontWeight: "bold",
                color: "#424242",
                fontFamily: "Roboto, sans-serif",
              }}
            >
              Must Exclude Tasks (Optional)
            </InputLabel>
            <TextareaAutosize
              id={`excludeTask`}
              name="excludeTask"
              value={formValues.excludeTask}
              onChange={handleFormChange}
              placeholder="Sometimes in your SWMS, you may want to exclude certain tasks that will be covered in a different SWMS document. For example, you might want to exclude scaffolding work or working with power tools to keep this document focused. Be clear about which tasks you intend to leave out so the SWMS remains relevant to the specific activities it covers."
              minRows={3}
              style={{
                width: "100%",
                padding: "8px",
                marginBottom: "8px",
                borderRadius: "5px",
                borderColor: "grey",
                fontFamily: "Roboto, sans-serif",
              }}
            />
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Box sx={{ flex: 1 }}>
                <InputLabel
                  htmlFor={`numberOfActivities`}
                  sx={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    color: "#424242",
                    fontFamily: "Roboto, sans-serif",
                  }}
                >
                  Number of Activities
                </InputLabel>
                <TextField
                  type="number"
                  name="numberOfActivities"
                  value={formValues.numberOfActivities}
                  onChange={handleFormChange}
                  variant="outlined"
                  size="small"
                  inputProps={{ min: min, max: max }}
                  sx={{
                    width: "100%",
                    bgcolor: "white",
                    borderRadius: "5px",
                    fontFamily: "Roboto, sans-serif",
                  }}
                />
              </Box>
              <Box sx={{ flex: 1 }}>
                <InputLabel
                  htmlFor="state"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    color: "#424242",
                    fontFamily: "Roboto, sans-serif",
                  }}
                >
                  Select State
                </InputLabel>
                <TextField
                  select
                  name="state"
                  value={formValues.state}
                  onChange={handleFormChange}
                  variant="outlined"
                  size="small"
                  sx={{
                    width: "100%",
                    bgcolor: "white",
                    borderRadius: "5px",
                    fontFamily: "Roboto, sans-serif",
                  }}
                >
                  {australianStates.map((state) => (
                    <MenuItem key={state} value={state}>
                      {state}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            </Box>
          </form>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={4000}
            onClose={handleSnackbarClose}
            message={snackbarMessage}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            action={
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleSnackbarClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          />
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          borderLeft: `4px solid ${documentColor}`,
          borderRight: `4px solid ${documentColor}`,
          borderBottom: `4px solid ${documentColor}`,
          borderRadius: "0 0 3px 3px",
          bgcolor: "#E9EAEC",
          padding: 2,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button
            onClick={handleSaveAndGoToDashboard}
            sx={{
              bgcolor: color,
              fontFamily: "Roboto, sans-serif",
              color: "black",
              borderRadius: "3px",
              textTransform: "none",
              boxShadow: 3,
              minWidth: "unset",
              width: "45px",
              height: "45px",
              padding: 0,
              "&:hover": {
                boxShadow: 10,
              },
              "&:active": {
                boxShadow: 2,
                bgcolor: "#d0d0d0",
              },
            }}
          >
            <ArrowBigLeft size={48} bgcolor="black" fill="black" />
          </Button>
          <Typography
            sx={{
              marginLeft: "10px",
              fontFamily: "Roboto, sans-serif",
              fontSize: "15px",
              fontWeight: "medium",
              color: "black",
            }}
          >
            Back to Dashboard
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button
            onClick={handleCancel}
            sx={{
              bgcolor: "red",
              fontFamily: "Roboto, sans-serif",
              color: fontColor,
              borderRadius: "3px",
              textTransform: "none",
              boxShadow: 3,
              "&:hover": {
                boxShadow: 4,
              },
              "&:active": {
                boxShadow: 2,
              },
              marginRight: 1,
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleClose}
            sx={{
              bgcolor: color,
              fontFamily: "Roboto, sans-serif",
              color: fontColor,
              borderRadius: "3px",
              textTransform: "none",
              boxShadow: 3,
              "&:hover": {
                boxShadow: 4,
              },
              "&:active": {
                boxShadow: 2,
              },
              marginRight: 1,
            }}
          >
            Close
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={loading}
            sx={{
              bgcolor: loading ? lighten(documentColor, 0.2) : documentColor,
              fontFamily: "Roboto, sans-serif",
              color: fontColor,
              borderRadius: "3px",
              textTransform: "none",
              boxShadow: loading ? 1 : 3,
              "&:hover": {
                boxShadow: 4,
              },
              "&:active": {
                boxShadow: 2,
              },
            }}
          >
            {loading ? (
              <>
                <CircularProgress
                  size={24}
                  sx={{ color: darken(color, 0.4) }}
                />
                <Typography
                  sx={{ marginLeft: "5px", color: darken(documentColor, 0.4) }}
                >
                  Submit
                </Typography>
              </>
            ) : (
              "Submit"
            )}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ColorPickerAndApiModal;
