import React, { useState, useContext, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Button,
  IconButton,
  Typography,
  Popover,
  Checkbox,
  Card,
  Box,
  FormControlLabel,
  CardContent,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";
import { UsersRound } from "lucide-react";
import { FormContext } from '../context/FormContext';

const PeopleInvolved = ({ color, fontColor }) => {
  const { peopleInvolvedData, handlePeopleInvolvedChange } = useContext(FormContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showDeleteIcon, setShowDeleteIcon] = useState(false);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (peopleInvolvedData.rowsPage1.length === 0) {
      handlePeopleInvolvedChange({
        rowsPage1: [
          { name: "", role: "" },
          { name: "", role: "" },
          { name: "", role: "" },
        ]
      });
    }
  }, [peopleInvolvedData, handlePeopleInvolvedChange]);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleAccordionChange = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  const handleAddRowPage1 = () => {
    const updatedRows = [...peopleInvolvedData.rowsPage1, { name: "", role: "" }];
    handlePeopleInvolvedChange({ ...peopleInvolvedData, rowsPage1: updatedRows });
  };

  const handleDeleteRow = (index) => {
    const updatedRows = peopleInvolvedData.rowsPage1.filter((_, rowIndex) => rowIndex !== index);
    handlePeopleInvolvedChange({ ...peopleInvolvedData, rowsPage1: updatedRows });
  };

  const handleInputChange = (index, field, value) => {
    const updatedRows = peopleInvolvedData.rowsPage1.map((row, rowIndex) =>
      rowIndex === index ? { ...row, [field]: value } : row
    );
    handlePeopleInvolvedChange({ ...peopleInvolvedData, rowsPage1: updatedRows });
  };

  const open = Boolean(anchorEl);
  const id = open ? "popover-anchor" : undefined;

  return (
    <Card
      sx={{
        boxShadow: 3,
        border: `4px solid ${color}`,
        backgroundColor: "white",
        opacity: 0.9,
      }}
    >
      <Accordion expanded={expanded} onChange={handleAccordionChange}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            backgroundColor: color,
            display: "flex",
            alignItems: "center",
            height: "60px",
            "&.Mui-expanded": {
              height: "60px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignContent: "center",
              width: "100%",
            }}
          >
            <IconButton
              aria-describedby={id}
              onClick={(e) => {
                if (expanded) {
                  e.stopPropagation();
                  handlePopoverOpen(e);
                }
              }}
              sx={{
                padding: 0,
                color: "black",
                mr: 1,
              }}
            >
              <UsersRound color={fontColor}/>
            </IconButton>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "Roboto, sans-serif",
                  fontWeight: "bold",
                  color: fontColor
                }}
                onClick={(e) => {
                  if (expanded) {
                    e.stopPropagation();
                    handlePopoverOpen(e);
                  }
                }}
              >
                People involved in the development of this SWMS
              </Typography>
            </Box>
          </Box>
        </AccordionSummary>

        <AccordionDetails sx={{ bgcolor: "#E9EAEC", padding: 2 }}>
          <CardContent
            sx={{
              padding: 0,
              marginBottom: 0,
              "&:last-child": {
                paddingBottom: 0,
              },
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    border: `2px solid ${color}`,
                    padding: 2,
                    marginBottom: 0,
                    borderRadius: "3px",
                    position: "relative",
                  }}
                >
                  <Table>
                    <TableHead>
                      <TableRow sx={{ alignItems: "center" }}>
                        <TableCell
                          sx={{
                            bgcolor: color,
                            border: "1px solid black",
                            fontWeight: "bold",
                            padding: 1,
                            textAlign: "center",
                          }}
                        >
                          Name of the person
                        </TableCell>
                        <TableCell
                          sx={{
                            bgcolor: color,
                            border: "1px solid black",
                            fontWeight: "bold",
                            padding: 1,
                            textAlign: "center",
                          }}
                        >
                          Role of the person
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {peopleInvolvedData.rowsPage1.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell
                            sx={{ border: "1px solid black", padding: 1 }}
                          >
                            <TextField
                              value={row.name}
                              size="small"
                              onChange={(e) => handleInputChange(index, "name", e.target.value)}
                              fullWidth
                              sx={{
                                bgcolor: "white",
                                fontFamily: "Roboto, sans-serif",
                                borderRadius: "5px",
                              }}
                              placeholder="Name"
                            />
                          </TableCell>
                          <TableCell
                            sx={{ border: "1px solid black", padding: 1 }}
                          >
                            <TextField
                              value={row.role}
                              size="small"
                              onChange={(e) => handleInputChange(index, "role", e.target.value)}
                              fullWidth
                              sx={{
                                bgcolor: "white",
                                fontFamily: "Roboto, sans-serif",
                                borderRadius: "5px",
                              }}
                              placeholder="Role"
                            />
                          </TableCell>
                          {showDeleteIcon && (
                            <TableCell sx={{ padding: 0, width: "30px" }}>
                              <IconButton
                                onClick={() => handleDeleteRow(index)}
                                style={{ color: "red", fontSize: "0.8rem" }}
                              >
                                <CancelIcon fontSize="small" />
                              </IconButton>
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </AccordionDetails>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Box sx={{ p: 3, display: "flex", flexDirection: "column", gap: 2 }}>
            <IconButton
              aria-label="close"
              onClick={handlePopoverClose}
              size="small"
              sx={{
                position: "absolute",
                top: 8,
                right: 8,
                color: "grey.500",
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showDeleteIcon}
                  onChange={() => setShowDeleteIcon(!showDeleteIcon)}
                  sx={{
                    color: color,
                    "&.Mui-checked": {
                      color: color,
                    },
                    "& .MuiCheckbox-root": {
                      color: color,
                      "&.Mui-checked": {
                        color: color,
                      },
                    },
                  }}
                />
              }
              label="Delete Rows"
            />
            <Button
              onClick={handleAddRowPage1}
              startIcon={<AddIcon />}
              variant="contained"
              sx={{
                bgcolor: color,
                fontFamily: "Roboto, sans-serif",
                color: "black",
                borderRadius: "3px",
                textTransform: "none",
                boxShadow: 3,
                "&:hover": {
                  boxShadow: 4,
                },
                "&:active": {
                  boxShadow: 2,
                },
              }}
            >
              Add a row
            </Button>
          </Box>
        </Popover>
      </Accordion>
    </Card>
  );
};

export default PeopleInvolved;