import React, { useState, useContext } from "react";
import { BrowserRouter as Router, Link, useNavigate } from "react-router-dom";
import Logo from "../assets/icrommLogoBlueLarge.png";
import { ChevronLeft, ChevronRight, FileText, Settings, Plus, Search, } from "lucide-react";
import { FormContext } from "../context/FormContext";
import Cookies from "js-cookie";
import {
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  IconButton,
  List,
  ListItem,
  Typography,
  useTheme,
} from "@mui/material"

export default function Dashboard() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { setOpenColorDialog, setLandingPageVisibility, setSwmsAutocompleteAppVisibility, setIsPageMode } = useContext(FormContext);


  const handleSWMSClick = () => {
    setLandingPageVisibility(false);
    setSwmsAutocompleteAppVisibility(true);
    setOpenColorDialog(false);
    setIsPageMode(true);
    navigate("/home")
  }

  const handleRedirectToNextApp = () => {
    try {
      const token = Cookies.get("token"); // If you need to keep using "token"
      if (!token) {
        alert("User is not authenticated.");
        return;
      }

      // Set the cookie for the assistance subdomain
      Cookies.set("token", token, {
        sameSite: "none",
        secure: true,
        domain: ".icromm.com",
        path: "/"
      });

      // Construct the URL with token
      const nextAppUrl = new URL("https://assistance.icromm.com");
      nextAppUrl.searchParams.set("token", token);

      console.log("Redirecting to assistance app...");
      window.location.href = nextAppUrl.toString();

    } catch (error) {
      console.error("Error during redirect:", error);
      alert("An error occurred while redirecting. Please try again.");
    }
  };

  return (
    <Box sx={{ minHeight: "100vh", bgcolor: "#4b5563" }}>
      {/* Main Content */}
      <div style={{ padding: 20, marginLeft: 30, marginRight: 20 }}>
        <Typography variant="h5" component="h1" sx={{ fontSize: { xs: "20px", sm: "22px", md: "24px" }, ml: 6, mb: 1, color: "#f5a623", fontWeight: "bold" }}>
          Welcome to the ICROMM autoTools
        </Typography>
        <Typography variant="body1" sx={{ fontSize: { xs: "15px", sm: "16px", md: "17px" }, ml: 6, mb: 4, fontSize: "17px" }} className="text-gray-300">
          Make your selection from the following:
        </Typography>

        <Box
          sx={{
            // width: '65vw',
            height: "auto",
            marginLeft: { xs: 0, md: 6 },
            marginRight: 16,
            display: "grid",
            gridTemplateColumns: {
              xs: "1fr",
              sm: "repeat(2, 1fr)",
              md: "repeat(3, 1fr)"
            },
            gap: { xs: 2, md: 4 }
          }}
        >
          {/* SWMS Card */}
          <Card sx={{
            bgcolor: "#1c1f26",
            color: "white",
            cursor: "pointer",
            transition: "all 0.2s ease-in-out",
            boxShadow: "0px 2px 10px rgba(11, 11, 11, 0.2)",
            "&:hover": {
              boxShadow: "0px 4px 20px rgba(11, 11, 11, 0.4)",
              filter: "brightness(1.2)",
            },
            "&:active": {
              boxShadow: "0px 2px 10px rgba(11, 11, 11, 0.3)",
              transform: "scale(0.98)",
            },
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
            onClick={handleSWMSClick}>
            <CardContent sx={{ pt: 2, display: "flex", flexDirection: "column", minHeight: "180px" }}>
              <Typography className="text-blue-400" sx={{ fontSize: "18px", mb: 1 }}>
                Get Started with autoSWMS
              </Typography>
              <List sx={{ margin: 0, p: 0, fontSize: "16px", flexGrow: 1 }} className="border-l-4 border-blue-500 pl-4 text-gray-300">
                <ListItem sx={{ p: 0, ml: 1 }}>1. Create your SWMS documents</ListItem>
                <ListItem sx={{ p: 0, ml: 1 }}>2. Manage your SWMS documents</ListItem>
              </List>
              <Box sx={{ display: "flex", flexGrow: 1, alignItems: "flex-end" }}>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{
                    marginTop: "10px",
                    padding: 0.2,
                    bgcolor: "#f5a623",
                    borderRadius: 3,
                    color: "black",
                    fontWeight: 600,
                    textTransform: "none",
                    "&:hover": { boxShadow: 4 },
                    "&:active": { boxShadow: 2 },
                  }}
                  onClick={handleSWMSClick}
                >
                  Let's Go!
                </Button>
              </Box>
            </CardContent>

          </Card>

          {/* HSMP Card */}
          <Card sx={{
            bgcolor: "#1c1f26",
            color: "white",
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}>
            <CardContent sx={{ pt: 2, display: "flex", flexDirection: "column", minHeight: "180px" }}>
              <Typography className="text-blue-400" sx={{ fontSize: "18px", mb: 1 }}>
                Get Started with autoHSMP
              </Typography>
              <List sx={{ margin: 0, p: 0, fontSize: "16px", flexGrow: 1 }} className="border-l-4 border-blue-500 pl-4 text-gray-300">
                <ListItem sx={{ p: 0, ml: 1 }}>1. Our autoHSMP is in development</ListItem>
                <ListItem sx={{ p: 0, ml: 1 }}>2. Beta 0.1 will be released soon</ListItem>
              </List>
              <Box sx={{ display: "flex", flexGrow: 1, alignItems: "flex-end" }}>
                <Button
                  fullWidth
                  sx={{
                    marginTop: "10px",
                    padding: 0.2,
                    bgcolor: "#828282",
                    borderRadius: 3,
                    color: "black",
                    fontWeight: 600,
                    textTransform: "none",
                    "&:hover": { boxShadow: 4 },
                    "&:active": { boxShadow: 2 },
                  }}
                >
                  Coming Soon!
                </Button>
              </Box>
            </CardContent>

          </Card>

          {/* AI Card */}
          <Card sx={{
            bgcolor: "#1c1f26",
            color: "white",
            cursor: "pointer",
            transition: "all 0.2s ease-in-out",
            boxShadow: "0px 2px 10px rgba(11, 11, 11, 0.2)",
            "&:hover": {
              boxShadow: "0px 4px 20px rgba(11, 11, 11, 0.4)",
              filter: "brightness(1.2)",
            },
            "&:active": {
              boxShadow: "0px 2px 10px rgba(11, 11, 11, 0.3)",
              transform: "scale(0.98)",
            },
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }} onClick={handleRedirectToNextApp}>
            <CardContent sx={{ pt: 2, display: "flex", flexDirection: "column", minHeight: "180px" }}>
              <Typography className="text-blue-400" sx={{ fontSize: "18px", mb: 1 }}>
                Try ICROMM AI
              </Typography>
              <List sx={{ m: 0, p: 0, fontSize: "16px", flexGrow: 1 }} className="border-l-4 border-blue-500 pl-4 text-gray-300">
                <ListItem sx={{ p: 0, ml: 1 }}>1. Trained on SWMS data</ListItem>
                <ListItem sx={{ p: 0, ml: 1, lineHeight: { xs: "inherit", sm: "inherit", md: "20px" }}}>
                  2. Has all the answers to your health and safety questions...
                </ListItem>
              </List>
              <Box sx={{ display: "flex", flexGrow: 1, alignItems: "flex-end" }}>
                <Button
                  fullWidth
                  variant="contained"
                  className="normal-case"
                  sx={{
                    marginTop: "10px",
                    padding: 0.2,
                    bgcolor: "#f5a623",
                    borderRadius: 3,
                    color: "black",
                    fontWeight: 600,
                    textTransform: "none",
                    "&:hover": { boxShadow: 4 },
                    "&:active": { boxShadow: 2 },
                  }}
                  onClick={handleRedirectToNextApp}
                >
                  Show Me!
                </Button>
              </Box>
            </CardContent>

          </Card>
        </Box>
      </div>
    </Box>
  )
}

