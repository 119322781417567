import React, { createContext, useState, useEffect, useContext, useCallback } from "react";
import axios from "axios";
import { useAuth } from "./AuthContext";
import Cookies from "js-cookie";
import uploadimg from '../assets/tempLogoPlaceholder.png';

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const { authToken } = useAuth();
  const DEFAULT_AVATAR = "https://p7.hiclipart.com/preview/442/477/305/computer-icons-user-profile-avatar-profile.jpg";
  const DEFAULT_LOGO = "https://asset.cloudinary.com/dk2lflesi/78a1560ed4b626df16ad2142c87fec78";

  const [user, setUser] = useState(() => {
    const savedUser = Cookies.get("user");
    return savedUser ? JSON.parse(savedUser) : { profile_image: DEFAULT_AVATAR };
  });
  const [loading, setLoading] = useState(true);
  const [documents, setDocuments] = useState([]); 

  const [logo, setLogo] = useState(uploadimg);
  const updateLogo = (newLogo) => {
    setLogo(newLogo);
  };

  const updateProfileImage = async (imageUrl) => {
    try {
      const response = await axios.patch(
        "https://www.icromm.com/api/users/me",
        { profile_image: imageUrl },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      
      const updatedUser = response.data.user;
      setUser(updatedUser);
      Cookies.set("user", JSON.stringify(updatedUser), { expires: 7 });
      return updatedUser;
    } catch (error) {
      console.error("Error updating profile image:", error);
      throw error;
    }
  };

  const updateUser = async (userData) => {
    if (authToken) {
      try {
        const response = await axios.patch(
          "https://www.icromm.com/api/users/me",
          userData,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        
        const updatedUser = {
          ...response.data.user,
          profile_image: response.data.user.profile_image || DEFAULT_AVATAR
        };
        
        setUser(updatedUser);
        Cookies.set("user", JSON.stringify(updatedUser), { expires: 7 });
        return updatedUser;
      } catch (error) {
        console.error("Error updating user details", error);
        throw error;
      }
    }
  };

  const fetchUserDetails = useCallback(async () => {
    if (authToken) {
      try {
        const response = await axios.get("https://www.icromm.com/api/users/me", {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        setUser(response.data);
        Cookies.set("user", JSON.stringify(response.data), { expires: 7 });
      } catch (error) {
        console.error("Error fetching user details", error);
      }
    }
    setLoading(false);
  }, [authToken]);

  const fetchUserDocuments = useCallback(async () => {
    if (authToken) {
      try {
        const documentsResponse = await axios.get("https://www.icromm.com/api/documents", {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        setDocuments(documentsResponse.data);
      } catch (error) {
        console.error("Error fetching user documents", error);
      }
    }
  }, [authToken]);

  useEffect(() => {
    fetchUserDetails();
  }, [fetchUserDetails]);

  const fetchLifetimeTokens = async () => {
    if (authToken) {
      try {
        const response = await axios.get("https://www.icromm.com/api/users/lifetime-tokens", {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        return response.data.lifetime_tokens_used;
      } catch (error) {
        console.error("Error fetching lifetime tokens", error);
        return 0;
      }
    }
    return 0;
  };

  return (
    <UserContext.Provider value={{ 
      user, 
      setUser, 
      loading, 
      updateUser,
      updateProfileImage,
      fetchUserDetails, 
      fetchUserDocuments, 
      logo, 
      updateLogo, 
      documents, 
      setDocuments, 
      fetchLifetimeTokens,
      DEFAULT_AVATAR,
      DEFAULT_LOGO
    }}>
      {children}
    </UserContext.Provider>
  );
};
