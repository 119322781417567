import React, { useState, useEffect } from "react";
import { useUser } from "../context/UserContext";
import { useNavigate } from "react-router-dom";
import {
  ChevronLeft,
  ChevronRight,
  FileText,
  Settings,
  Plus,
  Save,
  Download,
  Pencil,
} from "lucide-react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Cloudinary } from '@cloudinary/url-gen';
import { AdvancedImage } from '@cloudinary/react';
import { auto } from '@cloudinary/url-gen/actions/resize';
import { autoGravity } from '@cloudinary/url-gen/qualifiers/gravity';

ChartJS.register(ArcElement, Tooltip, Legend);

function SettingsPage() {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [profileImage, setProfileImage] = useState(
    "https://p7.hiclipart.com/preview/442/477/305/computer-icons-user-profile-avatar-profile.jpg"
  );

  const { user, loading, updateUser, fetchUserDetails, fetchLifetimeTokens } = useUser();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [totalCredits, setTotalCredits] = useState(2000000);
  const [usedCredits, setUsedCredits] = useState(0);
  const [lifetimeUsedCredits, setLifetimeUsedCredits] = useState(0);
  const [lifetimeTotalCredits, setLifetimeTotalCredits] = useState(2000000); // Set this to an appropriate value

  // useEffect(() => {
  //   const loadUserData = async () => {
  //     await fetchUserDetails(); // Refresh user data
  //     const lifetimeTokens = await fetchLifetimeTokens();
  //     setLifetimeUsedCredits(lifetimeTokens);
  //   };
  //   loadUserData();
  // }, [fetchUserDetails, fetchLifetimeTokens]);

  useEffect(() => {
    if (user) {
      setFirstName(user.first_name);
      setLastName(user.last_name);
      setPhone(user.phone);
      setEmail(user.email);
      setProfileImage(user.profile_image || "https://pic.onlinewebfonts.com/thumbnails/icons_529940.svg");
      
      // Set used credits directly from total_tokens_used
      setUsedCredits(user.total_tokens_used || 0);
    }
  }, [user]);

  useEffect(() => {
    const fetchTokens = async () => {
      const tokens = await fetchLifetimeTokens();
      setLifetimeUsedCredits(tokens);
    };
    fetchTokens();
  }, [fetchLifetimeTokens]);

  const remainingCredits = Math.max(totalCredits - usedCredits, 0);
  const remainingLifetimeCredits = Math.max(lifetimeTotalCredits - lifetimeUsedCredits, 0);

  const payments = [
    {
      id: 1,
      date: "2024-09-01",
      amount: "$50.00",
      status: "Paid",
      method: "Credit Card",
      transactionId: "TX123456",
    },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await updateUser({
        first_name: firstName,
        last_name: lastName,
        phone,
        profile_image: profileImage,
      });
      alert('Details saved successfully!');
    } catch (error) {
      console.error("Error updating user details", error);
      alert('Failed to save details. Please try again.');
    }
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        // Create form data for Cloudinary upload
        const formData = new FormData();
        formData.append('file', file);
        formData.append('upload_preset', 'swms_user-profile-images');

        // Upload to Cloudinary
        const response = await fetch(
          'https://api.cloudinary.com/v1_1/dk2lflesi/image/upload',
          {
            method: 'POST',
            body: formData,
          }
        );

        if (!response.ok) {
          throw new Error('Failed to upload image');
        }

        const data = await response.json();
        const imageUrl = data.secure_url;

        // Update profile image in state and backend
        setProfileImage(imageUrl);
        await updateUser({
          ...user,
          profile_image: imageUrl
        });

      } catch (error) {
        console.error('Error uploading image:', error);
      }
    }
  };

  const renderProfileImage = () => {
    const defaultImage = "https://pic.onlinewebfonts.com/thumbnails/icons_529940.svg";
    
    if (profileImage && profileImage.includes('res.cloudinary.com')) {
      const cld = new Cloudinary({
        cloud: {
          cloudName: 'dk2lflesi'
        }
      });

      const img = cld
        .image(profileImage.split('/').pop())
        .format('auto')
        .quality('auto')
        .resize(auto().gravity(autoGravity()).width(250).height(250)); // Set to 250x250

      return (
        <AdvancedImage
          cldImg={img}
          className="h-[250px] w-[250px] rounded-full border-4 border-gray-300 shadow-lg object-cover mx-auto"
        />
      );
    }

    // Return default image with same dimensions
    return (
      <img
        src={defaultImage}
        alt="Profile"
        className="h-[250px] w-[250px] rounded-full border-4 border-gray-300 shadow-lg object-cover mx-auto"
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = defaultImage;
        }}
      />
    );
  };

  const creditData = {
    labels: ["Used", "Remaining"],
    datasets: [
      {
        data: [usedCredits, remainingCredits],
        backgroundColor: ["#FF6384", "#36A2EB"],
        hoverBackgroundColor: ["#FF6384", "#36A2EB"],
      },
    ],
  };

  const lifetimeCreditData = {
    labels: ["Used Completions", "Remaining Completions"],
    datasets: [
      {
        data: [lifetimeUsedCredits, remainingLifetimeCredits],
        backgroundColor: ["#FF6384", "#36A2EB"],
        hoverBackgroundColor: ["#FF6384", "#36A2EB"],
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          font: {
            size: 14,
            weight: "bold",
          },
          padding: 20,
          usePointStyle: true,
          pointStyle: "circle",
        },
      },
    },
  };

  // Add this function to handle document deletion
  const handleDocumentDeletion = async (documentId) => {
    try {
      const response = await fetch(`/api/documents/${documentId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        // The usedCredits should not change when a document is deleted
        // So we don't need to update it here
        // console.log(`Document deleted. Tokens used: ${data.tokens_used}`);
      } else {
        console.error('Failed to delete document');
      }
    } catch (error) {
      console.error('Error deleting document:', error);
    }
  };

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8">
      <main className="py-8">
        <h1 className="mb-6 text-2xl font-bold text-[#f5a623]">Settings</h1>

        <div className="flex flex-col lg:flex-row justify-between w-full space-y-8 lg:space-y-0 lg:space-x-8">
          <div className="flex-1 flex flex-col">
            <h2 className="text-xl font-semibold mb-4 text-gray-300">
              User Details
            </h2>
            <form
              onSubmit={handleSubmit}
              className="bg-white p-4 sm:p-6 lg:p-8 rounded-lg shadow-lg flex flex-col justify-between flex-grow"
            >
              <div className="flex flex-col sm:flex-row items-center space-y-6 sm:space-y-0 sm:space-x-8">
                <div className="relative group w-full sm:w-auto">
                  {renderProfileImage()}
                  <label
                    htmlFor="image-upload"
                    className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-30 rounded-full cursor-pointer opacity-0 group-hover:opacity-100 transition-opacity"
                  >
                    <Pencil className="text-white h-6 w-6" />
                  </label>
                  <input
                    type="file"
                    id="image-upload"
                    className="hidden"
                    accept="image/*"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      const maxSize = 1024 * 1024; // 1MB in bytes
                      
                      if (file && file.size > maxSize) {
                        alert('File size must be less than 1MB');
                        e.target.value = ''; // Reset the input
                        return;
                      }
                      
                      handleImageUpload(e);
                    }}
                  />
                </div>

                <div className="flex-1 grid grid-cols-1 gap-6 w-full">
                  <div>
                    <label
                      htmlFor="firstName"
                      className="block text-sm font-medium text-gray-700"
                    >
                      First Name
                    </label>
                    <input
                      type="text"
                      id="firstName"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      className="mt-1 py-2 px-3 block w-full rounded-md border border-gray-400 shadow-sm focus:ring focus:ring-opacity-50"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="lastName"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Last Name
                    </label>
                    <input
                      type="text"
                      id="lastName"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      className="mt-1 py-2 px-3 block w-full rounded-md border border-gray-400 shadow-sm focus:ring focus:ring-opacity-50"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="phone"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Phone Number
                    </label>
                    <input
                      type="tel"
                      id="phone"
                      value={phone}
                      onChange={(e) => {
                        // Only allow numbers
                        const value = e.target.value.replace(/[^\d]/g, '');
                        setPhone(value);
                      }}
                      className="mt-1 py-2 px-3 block w-full rounded-md border border-gray-400 shadow-sm focus:ring focus:ring-opacity-50"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      value={email}
                      disabled
                      className="mt-1 py-2 px-3 block w-full rounded-md border border-gray-400 shadow-sm focus:ring focus:ring-opacity-50 bg-gray-200"
                      required
                    />
                    <button
                      type="submit"
                      className="mt-8 px-12 py-2 border border-green-500 text-green-500 rounded-lg shadow hover:bg-green-50 flex items-center justify-center space-x-2"
                    >
                      <Save className="h-4 w-4" />
                      <span>Save Changes</span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>

          {/* Credits section */}
          <div className="flex-1 flex flex-col">
            <h2 className="text-xl font-semibold mb-4 text-gray-300">
              Completions
            </h2>
            {/* <div className="bg-white p-4 sm:p-6 lg:p-8 rounded-lg shadow-lg flex flex-col justify-between flex-grow">
              <div className="flex-grow flex flex-col justify-center items-center">
                <div className="w-full h-64 mb-6">
                  <Pie data={creditData} options={chartOptions} />
                </div>
                <div className="text-center">
                  <p className="text-2xl font-bold text-gray-800">
                    {remainingCredits} / {totalCredits}
                  </p>
                  <p className="text-lg text-gray-600">Remaining Credits</p>
                </div>
              </div>
            </div> */}
            <div className="bg-white p-4 sm:p-6 lg:p-8 rounded-lg shadow-lg flex flex-col justify-between flex-grow">
              <div className="flex-grow flex flex-col justify-center items-center">
                <div className="w-full h-64 mb-6">
                  <Pie data={lifetimeCreditData} options={chartOptions} />
                </div>
                <div className="text-center">
                  <p className="text-2xl font-bold text-gray-800">
                    {remainingLifetimeCredits} / {lifetimeTotalCredits}
                  </p>
                  <p className="text-lg text-gray-600">Remaining Completions</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Payment Table */}
        <div className="mt-8">
          <h2 className="text-xl font-semibold mb-4 text-gray-300">Payment History</h2>
          <div className="overflow-x-auto">
            <div className="max-h-64 overflow-y-auto">
              <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow">
                <thead className="bg-gray-100 text-gray-600 uppercase text-sm leading-normal">
                  <tr>
                    <th className="py-3 px-4 sm:px-6 text-left">Date</th>
                    <th className="py-3 px-4 sm:px-6 text-left">Amount</th>
                    <th className="py-3 px-4 sm:px-6 text-left hidden sm:table-cell">
                      Status
                    </th>
                    <th className="py-3 px-4 sm:px-6 text-left hidden md:table-cell">
                      Method
                    </th>
                    <th className="py-3 px-4 sm:px-6 text-left hidden lg:table-cell">
                      Transaction ID
                    </th>
                    <th className="py-3 px-4 sm:px-6 text-left">Receipt</th>
                  </tr>
                </thead>
                <tbody className="text-gray-600 text-sm font-light">
                  {payments.length > 0 ? (
                    payments.map((payment) => (
                      <tr
                        key={payment.id}
                        className="border-b border-gray-200 hover:bg-gray-50 transition duration-200"
                      >
                        <td className="py-3 px-4 sm:px-6">{payment.date}</td>
                        <td className="py-3 px-4 sm:px-6">{payment.amount}</td>
                        <td className="py-3 px-4 sm:px-6 hidden sm:table-cell">
                          {payment.status}
                        </td>
                        <td className="py-3 px-4 sm:px-6 hidden md:table-cell">
                          {payment.method}
                        </td>
                        <td className="py-3 px-4 sm:px-6 hidden lg:table-cell">
                          {payment.transactionId}
                        </td>
                        <td className="py-3 px-4 sm:px-6">
                          <button className="flex items-center px-2 sm:px-4 py-2 border border-gray-700 text-gray-700 rounded-lg shadow hover:bg-gray-200 transition duration-200">
                            <Download className="h-4 w-4" />
                            <span className="ml-2 hidden sm:inline">
                              Download
                            </span>
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6" className="text-center py-4">
                        No payment history available.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* Add this section to display lifetime used credits */}
        {/* <div className="mt-8">
          <h2 className="text-xl font-semibold mb-4">Lifetime Usage</h2>
          <p className="text-lg text-gray-600">
            Total tokens used (lifetime): {lifetimeUsedCredits}
          </p>
        </div> */}

        {/* Existing credit usage chart */}
        {/* <div className="bg-white rounded-lg shadow-md p-6 mb-6">
          <h2 className="text-xl font-semibold mb-4">Current Credit Usage</h2>
          <div className="w-full h-64 mb-6">
            <Pie data={creditData} options={chartOptions} />
          </div>
          <div className="text-center">
            <p className="text-2xl font-bold text-gray-800">
              {remainingCredits} / {totalCredits}
            </p>
            <p className="text-lg text-gray-600">Remaining Credits</p>
          </div>
        </div> */}

        {/* New lifetime credit usage chart */}
        {/* <div className="bg-white rounded-lg shadow-md p-6 mb-6 mt-8">
          <h2 className="text-xl font-semibold mb-4">Lifetime Credit Usage</h2>
          <div className="w-full h-64 mb-6">
            <Pie data={lifetimeCreditData} options={chartOptions} />
          </div>
          <div className="text-center">
            <p className="text-2xl font-bold text-gray-800">
              {remainingLifetimeCredits} / {lifetimeTotalCredits}
            </p>
            <p className="text-lg text-gray-600">Remaining Lifetime Credits</p>
          </div>
        </div> */}
      </main>
    </div>
  );
}

export default SettingsPage;
