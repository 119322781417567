import React, { useState, useEffect, useContext } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  CardContent,
  Grid,
  Card,
  Box,
  InputLabel,
  TextareaAutosize,
  IconButton,
  Button,
  FormControlLabel,
  Checkbox,
  Popover,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import "@fontsource/roboto";
import { FormContext } from "../context/FormContext";

const HighLevelRisksCard = ({ color, q1, fontColor }) => {
  const { highLevelRisksData, handleHighLevelRiskChange } = useContext(FormContext);

  const [expanded, setExpanded] = useState(false);
  const [showDeleteIcons, setShowDeleteIcons] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (q1 && q1.length > 0) {
      const updatedRisks = q1.map((riskData, index) => ({
        id: index,
        number: index + 1,
        risk: riskData.risk,
        explanation: riskData.explanation,
        codeOfPractice: riskData.codeOfPractice,
        extendedInformation: riskData.extendedInformation
      }));
      handleHighLevelRiskChange(updatedRisks);
    } else if (highLevelRisksData.length === 0) {
      const defaultRisks = [
        { id: 0, number: "", risk: "", explanation: "", codeOfPractice: "", extendedInformation: "" },
        { id: 1, number: "", risk: "", explanation: "", codeOfPractice: "", extendedInformation: "" },
        { id: 2, number: "", risk: "", explanation: "", codeOfPractice: "", extendedInformation: "" }
      ];
      handleHighLevelRiskChange(defaultRisks);
    }
  }, [q1, handleHighLevelRiskChange]);

  const handleInputChange = (event, field, id) => {
    const { value } = event.target;
    const updatedRisks = highLevelRisksData.map(risk =>
      risk.id === id ? { ...risk, [field]: value } : risk
    );
    handleHighLevelRiskChange(updatedRisks);
  };

  const handleAddDescription = () => {
    const newRisk = {
      id: highLevelRisksData.length,
      number: highLevelRisksData.length + 1,
      risk: "",
      explanation: "",
      codeOfPractice: "",
      extendedInformation: ""
    };
    handleHighLevelRiskChange([...highLevelRisksData, newRisk]);
  };

  const handleRemoveRisk = (idToRemove) => {
    if (highLevelRisksData.length > 1) {
      const updatedRisks = highLevelRisksData.filter(risk => risk.id !== idToRemove)
        .map((risk, index) => ({ ...risk, number: index + 1 }));
      handleHighLevelRiskChange(updatedRisks);
    }
  };

  const handleAccordionChange = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  const handleIconClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const extractKeywords = (url) => {
    // Remove common prefixes and file extensions
    const cleanUrl = url.replace(/^(https?:\/\/)?(www\.)?/, '').replace(/\.[^/.]+$/, '');
    // Split the remaining string into words
    const words = cleanUrl.split(/[-.]+/);
    // Return up to 3 words, joined with spaces
    return words.slice(0, 3).join(' ');
  };

  const handleLinkClick = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const open = Boolean(anchorEl);
  const id = open ? "popover-anchor" : undefined;

  return (
    <Card
      sx={{
        boxShadow: 3,
        border: `4px solid ${color}`,
        backgroundColor: "white",
        opacity: 0.9,
      }}
    >
      <Accordion expanded={expanded} onChange={handleAccordionChange}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            backgroundColor: color,
            display: "flex",
            alignItems: "center",
            height: "60px",
            "&.Mui-expanded": {
              height: "50px",
            },
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
            <IconButton
              aria-describedby={id}
              onClick={(e) => {
                if (expanded) {
                  stopPropagation(e);
                  handleIconClick(e);
                }
              }}
              sx={{ padding: 0, color: fontColor }}
            >
              <WarningAmberIcon />
            </IconButton>
            <Typography
              variant="h6"
              sx={{
                ml: 1,
                fontFamily: "Roboto, sans-serif",
                fontWeight: "bold",
                color: fontColor
              }}
              onClick={(e) => {
                if (expanded) {
                  stopPropagation(e);
                  handleIconClick(e);
                }
              }}
            >
              High Level Risks
            </Typography>
          </Box>
        </AccordionSummary>

        <AccordionDetails sx={{ bgcolor: "#E9EAEC", padding: 2 }}>
          <CardContent
            sx={{
              padding: 0,
              marginBottom: 0,
              "&:last-child": {
                paddingBottom: 0,
              },
            }}
          >
            <Grid container spacing={2}>
              {highLevelRisksData.map((risk) => {
                return(
                  <Grid item xs={4} key={risk.id}>
                    <Box
                      sx={{
                        border: `2px solid ${color}`,
                        padding: 2,
                        marginBottom: 0,
                        borderRadius: "3px",
                        position: "relative",
                      }}
                    >
                      {showDeleteIcons && highLevelRisksData.length > 1 && (
                        <IconButton
                          onClick={() => handleRemoveRisk(risk.id)}
                          sx={{
                            position: "absolute",
                            top: "4px",
                            right: "4px",
                            padding: "0",
                            color: "red",
                          }}
                        >
                          <CancelIcon sx={{ fontSize: "15px" }} />
                        </IconButton>
                      )}
                      <InputLabel
                        htmlFor={`number-${risk.id}`}
                        sx={{
                          fontSize: "12px",
                          fontFamily: "Roboto, sans-serif",
                          fontWeight: "bold",
                          color: "#424242",
                        }}
                      >
                        High Level Risk
                      </InputLabel>
                      <TextField
                        fullWidth
                        id={`number-${risk.id}`}
                        name="number"
                        value={risk.number}
                        onChange={(e) => handleInputChange(e, "number", risk.id)}
                        variant="outlined"
                        size="small"
                        sx={{
                          bgcolor: "white",
                          width: "99%",
                          borderRadius: "5px",
                          fontFamily: "Roboto, sans-serif",
                          marginBottom: "20px",
                        }}
                      />

                      <InputLabel
                        htmlFor={`risk-${risk.id}`}
                        sx={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          color: "#424242",
                          fontFamily: "Roboto, sans-serif",
                        }}
                      >
                        Name of the Risk
                      </InputLabel>
                      <TextareaAutosize
                        id={`risk-${risk.id}`}
                        name="risk"
                        value={risk.risk}
                        onChange={(e) => handleInputChange(e, "risk", risk.id)}
                        minRows={2}
                        style={{
                          minWidth: "95%",
                          maxWidth: "95%",
                          padding: "8px",
                          fontFamily: "Roboto, sans-serif",
                          borderRadius: "5px",
                          borderColor: "grey",
                        }}
                      />
                      <InputLabel
                        htmlFor={`explanation-${risk.id}`}
                        sx={{
                          fontSize: "12px",
                          marginTop: "8px",
                          fontWeight: "bold",
                          color: "#424242",
                          fontFamily: "Roboto, sans-serif",
                        }}
                      >
                        Explanation
                      </InputLabel>
                      <TextareaAutosize
                        id={`explanation-${risk.id}`}
                        name="explanation"
                        value={risk.explanation}
                        onChange={(e) => handleInputChange(e, "explanation", risk.id)}
                        minRows={2}
                        style={{
                          minWidth: "95%",
                          maxWidth: "95%",
                          padding: "8px",
                          fontFamily: "Roboto, sans-serif",
                          borderRadius: "5px",
                          borderColor: "grey",
                        }}
                      />
                      <InputLabel
                        htmlFor={`codeOfPractice-${risk.id}`}
                        sx={{
                          fontSize: "12px",
                          marginTop: "8px",
                          fontWeight: "bold",
                          color: "#424242",
                          fontFamily: "Roboto, sans-serif",
                        }}
                      >
                        Code of practice
                      </InputLabel>
                      <TextareaAutosize
                        id={`codeOfPractice-${risk.id}`}
                        name="codeOfPractice"
                        value={risk.codeOfPractice && risk.codeOfPractice.startsWith('http')
                          ? extractKeywords(risk.codeOfPractice)
                          : risk.codeOfPractice}
                        onChange={(e) => handleInputChange(e, "codeOfPractice", risk.id)}
                        minRows={2}
                        style={{
                          minWidth: "95%",
                          maxWidth: "95%",
                          padding: "8px",
                          fontFamily: "Roboto, sans-serif",
                          borderRadius: "5px",
                          borderColor: "grey",
                          cursor: risk.codeOfPractice && risk.codeOfPractice.startsWith('http') ? 'pointer' : 'auto',
                          color: risk.codeOfPractice && risk.codeOfPractice.startsWith('http') ? 'blue' : 'inherit',
                          textDecoration: risk.codeOfPractice && risk.codeOfPractice.startsWith('http') ? 'underline' : 'none',
                        }}
                        onClick={() => {
                          if (risk.codeOfPractice && risk.codeOfPractice.startsWith('http')) {
                            handleLinkClick(risk.codeOfPractice);
                          }
                        }}
                      />
                      <InputLabel
                        htmlFor={`extendedInformation-${risk.id}`}
                        sx={{
                          fontSize: "12px",
                          marginTop: "8px",
                          fontWeight: "bold",
                          color: "#424242",
                          fontFamily: "Roboto, sans-serif",
                        }}
                      >
                        Extended Information
                      </InputLabel>
                      <TextareaAutosize
                        id={`extendedInformation-${risk.id}`}
                        name="extendedInformation"
                        value={risk.extendedInformation}
                        onChange={(e) => handleInputChange(e, "extendedInformation", risk.id)}
                        minRows={2}
                        style={{
                          minWidth: "95%",
                          maxWidth: "95%",
                          padding: "8px",
                          fontFamily: "Roboto, sans-serif",
                          borderRadius: "5px",
                          borderColor: "grey",
                          lineHeight: "1.5", // Ensures proper line spacing
                          boxSizing: "border-box", // Helps in calculating height properly
                          overflow: "hidden", // Prevents unnecessary scrollbar
                        }}
                      />
                      {/* {risk.codeOfPractice && risk.codeOfPractice.startsWith('http') && (
                      <Button
                        onClick={() => handleLinkClick(risk.codeOfPractice)}
                        sx={{
                          textTransform: 'none',
                          padding: '8px',
                          fontFamily: "Roboto, sans-serif",
                          color: 'blue',
                          textDecoration: 'underline',
                        }}
                      >
                        {extractKeywords(risk.codeOfPractice)}
                      </Button>
                    )} */}
                    </Box>
                  </Grid>
                )
              })}
            </Grid>
          </CardContent>
        </AccordionDetails>
      </Accordion>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            position: "relative",
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handlePopoverClose}
            size="small"
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: "grey.500",
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>

          <FormControlLabel
            control={
              <Checkbox
                checked={showDeleteIcons}
                onChange={(e) => setShowDeleteIcons(e.target.checked)}
                sx={{
                  color: color,
                  "&.Mui-checked": {
                    color: color,
                  },
                  "& .MuiCheckbox-root": {
                    color: color,
                    "&.Mui-checked": {
                      color: color,
                    },
                  },
                }}
              />
            }
            label="Edit High Level Risk"
          />

          <Button
            variant="contained"
            component="span"
            sx={{
              bgcolor: color,
              fontFamily: "Roboto, sans-serif",
              color: "black",
              borderRadius: "3px",
              textTransform: "none",
              boxShadow: 3,
              "&:hover": {
                boxShadow: 4,
              },
              "&:active": {
                boxShadow: 2,
              },
            }}
            onClick={handleAddDescription}
            startIcon={<AddIcon />}
          >
            Add High Level Risk
          </Button>
        </Box>
      </Popover>
    </Card>
  );
};

export default HighLevelRisksCard;
