import { useEffect, useRef, useState } from "react";
import { createNoise3D } from "simplex-noise";
import {
  Shield,
  ClipboardCheck,
  Leaf,
  DollarSign,
  Briefcase,
  HardHat,
  FileText,
  Bot,
} from "lucide-react";
import { Card, CardContent } from "../../components/ui/card";

const NUM_POINTS = 500;
const NOISE_STEP = 0.005;
const NOISE_AMOUNT = 15;
const BASE_RADIUS = 80;

const documentTypes = [
  {
    icon: ClipboardCheck,
    title: "QUALITY DOCUMENTS",
    subDocs: [
      "Defect management documents",
      "Punch lists (or snag lists)",
      "Lot management sheets",
      "Hold points and witness points",
      "Corrective action reports",
      "Test requests",
      "Quality checklists and reports",
      "RFI's",
      "ITP's",
    ],
  },
  {
    icon: Leaf,
    title: "ENVIRONMENTAL DOCUMENTS",
    subDocs: [
      "Applicable environmental permits",
      "Environmental monitoring forms",
      "Environmental checklists and reports",
    ],
  },
  {
    icon: DollarSign,
    title: "FINANCE DOCUMENTS",
    subDocs: [
      "Timesheets",
      "Dockets",
      "Purchase requests",
      "Project costing forms",
      "Planned vs. actual costing sheets",
    ],
  },
  {
    icon: Briefcase,
    title: "COMMERCIAL DOCUMENTS",
    subDocs: [
      "Site diaries",
      "Delay and change management forms",
      "Contractor pre-qualifications",
      "Variation management documents",
      "Meeting minutes sheets",
      "Site instructions",
      "Claims forms",
      "Extension of time forms",
    ],
  },
  {
    icon: HardHat,
    title: "PRODUCTION DOCUMENTS",
    subDocs: [
      "Shift reports",
      "Materials ordering and inspections",
      "Quantity planned vs. actuals",
      "Waste tracking",
      "Budgeting and forecasting",
      "Daily reporting",
    ],
  },
  {
    icon: Shield,
    title: "SAFETY DOCUMENTS",
    subDocs: [
      "Risk assessments",
      "SWMS",
      "Incident reports",
      "Safety inspection checklists",
      "Toolbox talk records",
      "PPE logs",
    ],
  },
];

export default function AIDocumentation() {
  const blobRef = useRef(null);
  const containerRef = useRef(null);
  const [lines, setLines] = useState([]);
  const noise3D = createNoise3D();

  useEffect(() => {
    let frame = 0;
    const animate = () => {
      frame++;
      updateBlobShape(frame * NOISE_STEP);
      requestAnimationFrame(animate);
    };
    animate();
  }, []);

  useEffect(() => {
    const updateLines = () => {
      if (!containerRef.current || !blobRef.current) return;

      const containerRect = containerRef.current.getBoundingClientRect();
      const blobRect = blobRef.current.getBoundingClientRect();
      const blobCenterX =
        blobRect.left + blobRect.width / 2 - containerRect.left;
      const blobCenterY =
        blobRect.top + blobRect.height / 2 - containerRect.top;

      const cardElements =
        containerRef.current.querySelectorAll(".document-card");
      const newLines = Array.from(cardElements).map((card, index) => {
        const cardRect = card.getBoundingClientRect();
        const cardCenterX =
          cardRect.left + cardRect.width / 2 - containerRect.left;
        const cardCenterY =
          cardRect.top + cardRect.height / 2 - containerRect.top;

        const angle =
          Math.atan2(cardCenterY - blobCenterY, cardCenterX - blobCenterX) *
          (180 / Math.PI);
        const gradientId = `line-gradient-${index}`;

        return {
          x1: blobCenterX,
          y1: blobCenterY,
          x2: cardCenterX,
          y2: cardCenterY,
          angle,
          gradientId,
        };
      });

      setLines(newLines);
    };

    const timer = setTimeout(() => {
      updateLines();
      window.addEventListener("resize", updateLines);
    }, 500);

    return () => {
      clearTimeout(timer);
      window.removeEventListener("resize", updateLines);
    };
  }, []);

  const updateBlobShape = (noiseStep) => {
    if (!blobRef.current) return;

    const points = [];
    for (let i = 0; i < NUM_POINTS; i++) {
      const angle = (i / NUM_POINTS) * Math.PI * 2;
      const noiseX = Math.cos(angle);
      const noiseY = Math.sin(angle);
      const distortion = noise3D(noiseX, noiseY, noiseStep) * NOISE_AMOUNT;
      const radius = BASE_RADIUS + distortion;
      const x = Math.cos(angle) * radius + 150;
      const y = Math.sin(angle) * radius + 150;
      points.push(`${x},${y}`);
    }

    blobRef.current.setAttribute("d", `M${points.join("L")}Z`);
  };

  useEffect(() => {
    const cards = document.querySelectorAll(".floating-card");
    cards.forEach((card) => {
      const randomX = Math.random() * 20 - 10;
      const randomY = Math.random() * 20 - 10;
      card.setAttribute(
        "style",
        `--random-x: ${randomX}px; --random-y: ${randomY}px;`
      );
    });
  }, []);

  return (
    <section
      id="ai-docs"
      className="relative pt-[15px] pb-20 bg-gray-900"
      ref={containerRef}
    >
      <div className="container mx-auto px-4 pt-10">
        <h2 className="text-4xl font-bold text-center mb-8 text-white relative z-10">
          AI-Powered Documentation System
        </h2>
        <p className="text-center text-gray-300 relative z-10 mb-8">
          Our AI-powered documentation system is currently in development,
          focusing initially on automating workplace safety documentation
          including Safe Work Method Statements (SWMS), Health and Safety
          Management Plans (HSMP), and Health, Safety and Environment (HSE)
          forms.
        </p>
        <div
          className="relative w-[300px] h-[300px] mx-auto mb-8 -mt-8"
          style={{ zIndex: 10 }}
        >
          <svg viewBox="0 0 300 300" className="w-full h-full">
            <defs>
              <linearGradient
                id="blob-gradient"
                x1="0%"
                y1="0%"
                x2="100%"
                y2="100%"
              >
                <stop offset="0%" stopColor="#ff6b6b" />
                <stop offset="50%" stopColor="#4ecdc4" />
                <stop offset="100%" stopColor="#45b7d8" />
              </linearGradient>
              <filter id="goo">
                <feGaussianBlur
                  in="SourceGraphic"
                  stdDeviation="10"
                  result="blur"
                />
                <feColorMatrix
                  in="blur"
                  mode="matrix"
                  values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
                  result="goo"
                />
              </filter>
              <filter id="wave">
                <feTurbulence
                  id="turbulence"
                  type="fractalNoise"
                  baseFrequency="0.02 0.1"
                  numOctaves="2"
                  result="noise"
                />
                <feDisplacementMap
                  in="SourceGraphic"
                  in2="noise"
                  scale="10"
                  xChannelSelector="R"
                  yChannelSelector="G"
                />
              </filter>
              <animate
                xlinkHref="#turbulence"
                attributeName="baseFrequency"
                dur="30s"
                keyTimes="0;0.25;0.5;0.75;1"
                values="0.02 0.1;0.04 0.08;0.02 0.12;0.03 0.09;0.02 0.1"
                repeatCount="indefinite"
              />
            </defs>
            <g filter="url(#goo)">
              <path
                ref={blobRef}
                fill="url(#blob-gradient)"
                d="M150,150 L150,150 L150,150 L150,150 L150,150 L150,150 Z"
              />
            </g>
            {/* Replace <text> with the icon */}
            <g filter="url(#wave)" transform="translate(125, 120)">
              {" "}
              {/* Adjust the translate values as needed */}
              {/* <Bot size={60} color="yellow" /> Set size and color */}
            </g>
          </svg>
        </div>
        <svg
          className="absolute top-0 left-0 w-full h-full pointer-events-none"
          style={{ zIndex: 5 }}
        >
          <defs>
            {lines.map((line) => (
              <linearGradient
                key={line.gradientId}
                id={line.gradientId}
                gradientUnits="userSpaceOnUse"
                x1={line.x1}
                y1={line.y1}
                x2={line.x2}
                y2={line.y2}
              >
                <stop offset="0%" stopColor="#45b7d8" />
                <stop offset="50%" stopColor="#4ecdc4" />
                <stop offset="100%" stopColor="#ff6b6b" />
              </linearGradient>
            ))}
            <filter id="glow">
              <feGaussianBlur stdDeviation="3.5" result="coloredBlur" />
              <feMerge>
                <feMergeNode in="coloredBlur" />
                <feMergeNode in="SourceGraphic" />
              </feMerge>
            </filter>
          </defs>
          {lines.map((line, index) => (
            <g key={index}>
              <line
                x1={line.x1}
                y1={line.y1}
                x2={line.x2}
                y2={line.y2}
                stroke={`url(#${line.gradientId})`}
                strokeWidth="1"
              />
              <line
                x1={line.x1}
                y1={line.y1}
                x2={line.x2}
                y2={line.y2}
                stroke={`url(#${line.gradientId})`}
                strokeWidth="2"
                filter="url(#glow)"
                className="flow-line"
              />
            </g>
          ))}
        </svg>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-2 mt-12 relative z-20">
          {documentTypes.map((doc, index) => (
            <div key={index} className="relative">
              <Card className="document-card bg-gray-800 border-gray-700 hover:bg-gray-700 transition-colors duration-300 cursor-pointer group">
                <CardContent className="p-2 flex flex-col items-center justify-center text-center">
                  <doc.icon className="w-6 h-6 mb-1 text-yellow-400 group-hover:text-yellow-300 transition-colors duration-300" />
                  <h3 className="text-xs font-semibold text-white group-hover:text-yellow-300 transition-colors duration-300">
                    {doc.title}
                  </h3>
                </CardContent>
              </Card>
              {doc.subDocs.map((subDoc, subIndex) => (
                <div
                  key={subIndex}
                  className="absolute w-6 h-6 bg-gray-700 rounded-full flex items-center justify-center cursor-pointer transition-all duration-300 floating-card group"
                  style={{
                    left: `${Math.random() * 90 + 5}%`,
                    top: `${Math.random() * 90 + 5}%`,
                    animationDelay: `${Math.random() * 10}s`,
                    animationDuration: `${Math.random() * 5 + 5}s`,
                  }}
                >
                  <FileText className="w-3 h-3 text-yellow-400 group-hover:scale-[3] transition-transform duration-300" />
                  <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs p-2 rounded opacity-0 transition-all duration-300 whitespace-nowrap pointer-events-none mb-2 group-hover:mb-8">
                    {subDoc}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      <style jsx="true">{`
        @keyframes flowAnimation {
          0% {
            stroke-dasharray: 0 100%;
          }
          50% {
            stroke-dasharray: 100% 0;
          }
          100% {
            stroke-dasharray: 0 100%;
          }
        }
        .flow-line {
          stroke-dasharray: 10 10;
          animation: flowAnimation 3s linear infinite;
        }
        @keyframes float {
          0%,
          100% {
            transform: translate(0, 0);
          }
          50% {
            transform: translate(var(--random-x), var(--random-y));
          }
        }
        .floating-card {
          animation: float 5s ease-in-out infinite;
        }
        .floating-card:hover {
          transform: scale(3);
          z-index: 40;
        }
        .floating-card:hover > div {
          opacity: 1;
        }
      `}</style>
    </section>
  );
}
