import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ChevronLeft, ChevronRight, FileText, Settings, Plus, BotMessageSquare, HelpCircle, CreditCard, NotebookPen, MessageCircleReply, Menu} from "lucide-react";
import { useAuth } from "../context/AuthContext";
import { FormContext } from '../context/FormContext';
import Logo from "../assets/icrommLogoBlueLarge.png";
import { useUser } from "../context/UserContext";
import { Cloudinary } from '@cloudinary/url-gen';
import { AdvancedImage } from '@cloudinary/react';
import { auto } from '@cloudinary/url-gen/actions/resize';
import { autoGravity } from '@cloudinary/url-gen/qualifiers/gravity';
import { MenuItem, IconButton, Avatar, Divider, ListItemIcon, Typography } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import Cookies from "js-cookie";
import * as Tooltip from '@radix-ui/react-tooltip';

function UserLayout({ children }) {
  const { resetFormData } = useContext(FormContext);
  const { user, loading, fetchUserDetails, DEFAULT_AVATAR, DEFAULT_LOGO } = useUser();
  const { logout } = useAuth();

  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [activeTab, setActiveTab] = useState("/home");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    fetchUserDetails();
  }, [fetchUserDetails]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    localStorage.setItem("activeTab", tab);
  };

  const handleLogout = () => {
    console.log("handle Logout");
    logout();
    localStorage.removeItem("activeTab");
    navigate("/login");
  };

  const handleCreateDocument = () => {
    // resetFormData();
    handleTabClick("/home");
    navigate("/home");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = () => {
    console.log("handle Logout click");
    handleClose();
    handleLogout();
    removeAllCookies();
  };
  
  const removeAllCookies = () => {
    const cookies = document.cookie.split(';');
  
    cookies.forEach((cookie) => {
      const cookieName = cookie.split('=')[0].trim();
      // Add domain and path to ensure correct removal
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.icromm.com;`;
    });
  };
  
  

  const renderProfileImage = () => {
    if (user?.profile_image && user.profile_image.includes('res.cloudinary.com')) {
      const cld = new Cloudinary({
        cloud: {
          cloudName: 'dk2lflesi'
        }
      });

      const img = cld
        .image(user.profile_image.split('/').pop())
        .format('auto')
        .quality('auto')
        .resize(auto().gravity(autoGravity()).width(32).height(32));

      return (
        <AdvancedImage
          cldImg={img}
          className="h-8 w-8 rounded-full object-cover border-2 border-gray-200"
        />
      );
    }

    return (
      <img
        src={DEFAULT_AVATAR}
        alt="User"
        className="h-8 w-8 rounded-full object-cover border-2 border-gray-200"
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = DEFAULT_AVATAR;
        }}
      />
    );
  };

  const handleRedirectToNextApp = () => {
    try {
      const token = Cookies.get("token"); // If you need to keep using "token"
      if (!token) {
        alert("User is not authenticated.");
        return;
      }
  
      // Set the cookie for the assistance subdomain
      Cookies.set("token", token, {
        sameSite: "none",
        secure: true,
        domain: ".icromm.com",
        path: "/"
      });
  
      // Construct the URL with token
      const nextAppUrl = new URL("https://assistance.icromm.com");
      nextAppUrl.searchParams.set("token", token);
      
      console.log("Redirecting to assistance app...");
      window.location.href = nextAppUrl.toString();
  
    } catch (error) {
      console.error("Error during redirect:", error);
      alert("An error occurred while redirecting. Please try again.");
    }
  };

  const handleMenuClick = () => {
    setActiveTab('/home')
  }

  return (
    <div>
      <div className="flex h-screen bg-[#4b5563]">
        {/* Sidebar */}
        <div
          className={`text-white bg-[#1f2937] transition-all duration-300 ease-in-out shadow-md ${
            isSidebarCollapsed ? "w-16" : "w-56"
          }`}
        >
          <Link
              to="/user-dashboard"
              className={'flex items-center text-white text-2xl font-bold'}
              onClick={handleMenuClick}
            >
              {isSidebarCollapsed ? <span className="px-4 py-4 pt-6"><Menu className="h-5 w-5" /></span> : <span className="flex p-4 mt-3 ml-3 gap-2 items-center"><Menu className="h-5 w-5" />Menu</span>}
            </Link>
          <nav className="space-y-5 mt-2 p-2 text-white">
            <Link
              to="/user-dashboard/all-documents"
              className={`flex items-center space-x-2 rounded-lg px-2 py-2 text-white hover:bg-gray-700 ${
                activeTab === "/user-dashboard/all-documents"
                  ? "bg-gray-700"
                  : ""
              }`}
              onClick={() => handleTabClick("/user-dashboard/all-documents")}
            >
              <FileText className="h-5 w-5" />
              {!isSidebarCollapsed && <span>Documents</span>}
            </Link>
            <Link
              to="/user-dashboard/buy-token"
              className={`flex items-center space-x-2 rounded-lg px-2 py-2 text-white hover:bg-gray-700 ${
                activeTab === "/user-dashboard/buy-token" ? "bg-gray-700" : ""
              }`}
              onClick={() => handleTabClick("/user-dashboard/buy-token")}
            >
              <CreditCard className="h-5 w-5" />
              {!isSidebarCollapsed && <span>Buy Completions</span>}
            </Link>
            <Link
              to="#"
              className={`flex items-center space-x-2 rounded-lg px-2 py-2 text-white hover:bg-gray-700 ${
                activeTab === "/user-dashboard/icromm" ? "bg-gray-700" : ""
              }`}
              onClick={handleRedirectToNextApp}
            >
              <BotMessageSquare className="h-5 w-5" />
              {!isSidebarCollapsed && <span>ICROMM AI</span>}
            </Link>
            <Link
              to="/user-dashboard/guide"
              className={`flex items-center space-x-2 rounded-lg px-2 py-2 hover:bg-gray-700 ${
                activeTab === "/user-dashboard/guide" ? "bg-gray-700" : ""
              }`}
              onClick={() => handleTabClick("/user-dashboard/guide")}
            >
              <HelpCircle className="h-5 w-5" />
              {!isSidebarCollapsed && <span>Guide</span>}
            </Link>
            <Link
              to="/user-dashboard/feedback"
              className={`flex items-center space-x-2 rounded-lg px-2 py-2 hover:bg-gray-700 ${
                activeTab === "/user-dashboard/feedback" ? "bg-gray-700" : ""
              }`}
              onClick={() => handleTabClick("/user-dashboard/feedback")}
            >
              <MessageCircleReply className="h-5 w-5"/>
              {!isSidebarCollapsed && <span>Feedback</span>}
            </Link>
            <Link
              to="/user-dashboard/agreements"
              className={`flex items-center space-x-2 rounded-lg px-2 py-2 hover:bg-gray-700 ${
                activeTab === "/user-dashboard/agreements" ? "bg-gray-700" : ""
              }`}
              onClick={() => handleTabClick("/user-dashboard/agreements")}
            >
              <NotebookPen className="h-5 w-5"/>
              {!isSidebarCollapsed && <span>Agreements</span>}
            </Link>
            <hr className="my-2 border-t border-white opacity-50" />
            <Link
              to="/user-dashboard/settings"
              className={`flex items-center space-x-2 rounded-lg px-2 py-1 hover:bg-gray-700 ${
                activeTab === "/user-dashboard/settings" ? "bg-gray-700" : ""
              }`}
              onClick={() => handleTabClick("/user-dashboard/settings")}
            >
              <Settings className="h-5 w-5" />
              {!isSidebarCollapsed && <span>Settings</span>}
            </Link>
            <Link
              className={'flex items-center space-x-2 rounded-lg px-2 py-1 hover:bg-gray-700'}
              onClick={handleLogoutClick}
            >
              <LogoutIcon className="h-5 w-5" />
              {!isSidebarCollapsed && <span>LogOut</span>}
            </Link>
          </nav>
        </div>

        {/* Main content */}
        <div className="flex flex-1 flex-col overflow-hidden">
          {/* Header */}
          <header className="flex items-center pr-9 justify-between bg-white p-3 shadow">
          <div className="flex-row items-center">
          <button
              className="rounded-full focus:outline-none p-0"
              onClick={() => setIsSidebarCollapsed(!isSidebarCollapsed)}
              aria-label={
                isSidebarCollapsed ? "Expand sidebar" : "Collapse sidebar"
              }
            >
              {isSidebarCollapsed ? (
              <div className="flex text-[#1f2937]">
                <ChevronRight className="h-5 w-5" />
                <div className="text-gray-700 ml-7">{`Welcome, ${user?.first_name} ${user?.last_name}`}</div>
              </div>
              ) : (
              <div className="flex text-[#1f2937]">
                <ChevronLeft className="h-5 w-5" />
                <div className="text-gray-700 ml-7">{`Welcome, ${user?.first_name} ${user?.last_name}`}</div>
              </div>
              )}
            </button>
            </div>
            <div className="flex items-center relative">
            <div className="flex-end text-center r-0">
              <img src={Logo} alt="Logo" className="mx-auto h-10" />
            </div>
            </div>
          </header>

          {/* Modified main content wrapper with padding bottom for footer */}
          <main className="flex-1 overflow-y-auto p-7 pb-16">{children}</main>

          {/* New Footer */}
          <footer className="fixed bottom-0 w-full bg-[#1f2937] p-4 text-center pr-60 text-sm text-[#9CA3AF]">
            By using this software, you acknowledge and agree to the privacy terms and software agreement available at:{' '}
            <Link to="/user-dashboard/agreements" className="text-[#f5a623] hover:underline, opacity-[0.5]">
              https://www.icromm.com/user-dashboard/agreements
            </Link>
          </footer>
        </div>
      </div>
    </div>
  );
}

export default UserLayout;
