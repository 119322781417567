import React, { useState, useEffect, useContext } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  CardContent,
  Grid,
  TextField,
  Card,
  Box,
  InputLabel,
  TextareaAutosize,
  IconButton,
  Button,
  Checkbox,
  FormControlLabel,
  Popover,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";
import { ScrollText } from "lucide-react";
import "@fontsource/roboto";
import { FormContext } from "../../context/FormContext";

const LegislativeAndAcops = ({ color, q6, fontColor }) => {
  const { legislativeListData, handleLegislativeListChange, documentColor } = useContext(FormContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const [showDeleteIcons, setShowDeleteIcons] = useState(false);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (q6 && q6.length > 0) {
      const updatedLegislativeList = q6.map((item, index) => ({
        id: index,
        number: item.Number,
        activity: item.Activity,
        legislativeReference: Array.isArray(item["List the Legislative Reference and ACOPs"])
          ? item["List the Legislative Reference and ACOPs"].map((proc) => `- ${proc.trim()}`).join("\n")
          : item["List the Legislative Reference and ACOPs"],
        whyLegislation: Array.isArray(item["Why the legislation and ACOPs apply"])
          ? item["Why the legislation and ACOPs apply"].map((proc) => `- ${proc.trim()}`).join("\n")
          : item["Why the legislation and ACOPs apply"],
      }));

      handleLegislativeListChange(updatedLegislativeList);
    } else if (!legislativeListData || legislativeListData.length === 0) {
      // Ensure there's always at least one group field
      handleLegislativeListChange([{
        id: 0,
        number: "",
        activity: "",
        legislativeReference: "",
        whyLegislation: "",
      }]);
    } },[legislativeListData]);

  const handleIconClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleAccordionChange = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  const handleAddLegislation = () => {
    const newLegislation = {
      id: legislativeListData.length,
      number: "",
      activity: "",
      legislativeReference: "",
      whyLegislation: "",
    };
    handleLegislativeListChange([...legislativeListData, newLegislation]);
  };

  const handleLegislationChange = (id, e) => {
    const { name, value } = e.target;
    const updatedList = legislativeListData.map((item) =>
      item.id === id ? { ...item, [name]: value } : item
    );
    handleLegislativeListChange(updatedList);
  };

  const handleDeleteLegislation = (id) => {
    if (legislativeListData.length > 1) {
      const updatedList = legislativeListData.filter((item) => item.id !== id);
      handleLegislativeListChange(updatedList);
    }
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const open = Boolean(anchorEl);
  const id = open ? "popover-anchor" : undefined;

  return (
    <Card
      sx={{
        boxShadow: 3,
        border: `4px solid ${documentColor}`,
        backgroundColor: "white",
        opacity: 0.9,
      }}
    >
      <Accordion expanded={expanded} onChange={handleAccordionChange}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            backgroundColor: documentColor,
            display: "flex",
            alignItems: "center",
            height: "60px",
            "&.Mui-expanded": {
              height: "50px",
            },
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
            <IconButton
              aria-describedby={id}
              onClick={(e) => {
                if (expanded) {
                  stopPropagation(e);
                  handleIconClick(e);
                }
              }}
              sx={{ padding: 0, color: fontColor }}
            >
              <ScrollText />
            </IconButton>
            <Typography
              variant="h6"
              sx={{
                ml: 1,
                fontFamily: "Roboto, sans-serif",
                fontWeight: "bold",
                color: fontColor
              }}
              onClick={(e) => {
                if (expanded) {
                  stopPropagation(e);
                  handleIconClick(e);
                }
              }}
            >
              Legislative Reference and ACOPs
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ bgcolor: "#E9EAEC", padding: 2 }}>
          <CardContent
            sx={{
              padding: 0,
              marginBottom: 0,
              "&:last-child": {
                paddingBottom: 0,
              },
            }}
          >
            {legislativeListData && legislativeListData.map((item, index) => (
              <Box
                key={index}
                sx={{
                  border: `2px solid ${documentColor}`,
                  padding: 2,
                  borderRadius: "3px",
                  mb: 2,
                  position: "relative",
                  "&:last-child": {
                    mb: 0,
                  },
                }}
              >
                {showDeleteIcons && legislativeListData.length > 1 && (
                  <IconButton
                    onClick={() => handleDeleteLegislation(item.id)}
                    sx={{
                      position: "absolute",
                      top: "4px",
                      right: "4px",
                      padding: "0",
                      color: "red",
                    }}
                  >
                    <CancelIcon sx={{ fontSize: "15px" }} />
                  </IconButton>
                )}
                <Grid container spacing={2}>
                  <Grid container item spacing={2} xs={12}>
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor={`number-${item.id}`}
                        sx={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          color: "#424242",
                          fontFamily: "Roboto, sans-serif",
                        }}
                      >
                        Number
                      </InputLabel>
                      <TextField
                        fullWidth
                        id={`number-${item.id}`}
                        name="number"
                        value={item.number}
                        onChange={(e) => handleLegislationChange(item.id, e)}
                        variant="outlined"
                        size="small"
                        sx={{
                          bgcolor: "white",
                          borderRadius: "5px",
                          fontFamily: "Roboto, sans-serif",
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor={`activity-${item.id}`}
                        sx={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          color: "#424242",
                          fontFamily: "Roboto, sans-serif",
                        }}
                      >
                        Activity
                      </InputLabel>
                      <TextField
                        fullWidth
                        id={`activity-${item.id}`}
                        name="activity"
                        value={item.activity}
                        onChange={(e) => handleLegislationChange(item.id, e)}
                        variant="outlined"
                        size="small"
                        sx={{
                          bgcolor: "white",
                          borderRadius: "5px",
                          fontFamily: "Roboto, sans-serif",
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel
                      htmlFor={`legislativeReference-${item.id}`}
                      sx={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        color: "#424242",
                        fontFamily: "Roboto, sans-serif",
                      }}
                    >
                      Legislative Reference and ACOPs
                    </InputLabel>
                    <TextareaAutosize
                      id={`legislativeReference-${item.id}`}
                      name="legislativeReference"
                      value={item.legislativeReference}
                      onChange={(e) => handleLegislationChange(item.id, e)}
                      minRows={4}
                      style={{
                        minWidth: "98.7%",
                        maxWidth: "98.7%",
                        padding: "8px",
                        marginTop: "8px",
                        borderRadius: "5px",
                        borderColor: "grey",
                        fontFamily: "Roboto, sans-serif",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel
                      htmlFor={`whyLegislation-${item.id}`}
                      sx={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        color: "#424242",
                        fontFamily: "Roboto, sans-serif",
                      }}
                    >
                      Why the Legislation and ACOPs Apply
                    </InputLabel>
                    <TextareaAutosize
                      id={`whyLegislation-${item.id}`}
                      name="whyLegislation"
                      onChange={(e) => handleLegislationChange(item.id, e)}
                      value={item.whyLegislation}
                      minRows={4}
                      style={{
                        minWidth: "98.7%",
                        maxWidth: "98.7%",
                        padding: "8px",
                        marginTop: "8px",
                        borderRadius: "5px",
                        borderColor: "grey",
                        fontFamily: "Roboto, sans-serif",
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            ))}
          </CardContent>
        </AccordionDetails>
      </Accordion>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ p: 3, display: "flex", flexDirection: "column", gap: 2 }}>
          <IconButton
            aria-label="close"
            onClick={handlePopoverClose}
            size="small"
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: "grey.500",
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
          <FormControlLabel
            control={
              <Checkbox
                checked={showDeleteIcons}
                onChange={(e) => setShowDeleteIcons(e.target.checked)}
                sx={{
                  color: documentColor,
                  "&.Mui-checked": {
                    color: documentColor,
                  },
                }}
              />
            }
            label="Edit Section"
          />
          <Button
            variant="contained"
            sx={{
              bgcolor: documentColor,
              fontFamily: "Roboto, sans-serif",
              color: "black",
              borderRadius: "3px",
              textTransform: "none",
              boxShadow: 3,
              "&:hover": {
                boxShadow: 4,
              },
              "&:active": {
                boxShadow: 2,
              },
            }}
            startIcon={<AddIcon />}
            onClick={handleAddLegislation}
          >
            Add Section
          </Button>
        </Box>
      </Popover>
    </Card>
  );
};

export default LegislativeAndAcops;