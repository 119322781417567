import React, { useState, useEffect, useContext } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  CardContent,
  Grid,
  Card,
  Box,
  InputLabel,
  TextField,
  TextareaAutosize,
  IconButton,
  Button,
  Popover,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Users } from "lucide-react";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import CancelIcon from "@mui/icons-material/Cancel";
import "@fontsource/roboto";
import { FormContext } from "../../context/FormContext";

const PersonsInvolvedCard = ({ color, q3, fontColor }) => {
  const { personsInvolvedCardData, handlePersonsInvolvedCardChange, documentColor } = useContext(FormContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const [showDeleteIcons, setShowDeleteIcons] = useState(false);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (q3 && q3.length > 0) {
      const processedSections = q3.map((job, index) => {
        const jobTitle = Array.isArray(job["Job Title and Description of Person Involved in the Activity"])
          ? job["Job Title and Description of Person Involved in the Activity"].join(" ")
          : job["Job Title and Description of Person Involved in the Activity"];

        const rolesResponsibilities = Array.isArray(job["Roles and Responsibilities of That Person"])
          ? job["Roles and Responsibilities of That Person"].map((proc) => `- ${proc.trim()}`).join("\n")
          : job["Roles and Responsibilities of That Person"];

        const qualificationsExperience = Array.isArray(job["Qualifications & Experience Required for Person Involved in Activity"])
          ? job["Qualifications & Experience Required for Person Involved in Activity"].map((qual) => `- ${qual.trim()}`).join("\n")
          : job["Qualifications & Experience Required for Person Involved in Activity"];

        return {
          id: index,
          jobTitle: jobTitle,
          rolesResponsibilities: rolesResponsibilities,
          qualificationsExperience: qualificationsExperience,
        };
      });

      handlePersonsInvolvedCardChange(processedSections);
    } else if (personsInvolvedCardData.length === 0) {
      handlePersonsInvolvedCardChange([{
        id: 0,
        jobTitle: "",
        rolesResponsibilities: "",
        qualificationsExperience: "",
      }]);
    }
  }, [q3, handlePersonsInvolvedCardChange]);

  const handlePopoverClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleAccordionChange = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  const handleAddSection = () => {
    const newSection = {
      id: personsInvolvedCardData.length,
      jobTitle: "",
      rolesResponsibilities: "",
      qualificationsExperience: "",
    };
    handlePersonsInvolvedCardChange([...personsInvolvedCardData, newSection]);
  };

  const handleDeleteSection = (id) => {
    if (personsInvolvedCardData.length > 1) {
      const updatedSections = personsInvolvedCardData.filter((section) => section.id !== id);
      handlePersonsInvolvedCardChange(updatedSections);
    }
  };

  const handleSectionChange = (id, e) => {
    const { name, value } = e.target;
    const updatedSections = personsInvolvedCardData.map((section) =>
      section.id === id
        ? {
          ...section,
          [name]: name === 'jobTitle'
            ? value
            : value.split('\n').filter(item => item.trim() !== '')
        }
        : section
    );
    handlePersonsInvolvedCardChange(updatedSections);
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const open = Boolean(anchorEl);
  const id = open ? "popover-anchor" : undefined;

  return (
    <Card
      sx={{
        boxShadow: 3,
        border: `4px solid ${documentColor}`,
        backgroundColor: "white",
        opacity: 0.9,
      }}
    >
      <Accordion expanded={expanded} onChange={handleAccordionChange}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            backgroundColor: documentColor,
            display: "flex",
            alignItems: "center",
            height: "60px",
            "&.Mui-expanded": {
              height: "50px",
            },
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
            <IconButton
              aria-describedby={id}
              onClick={(e) => {
                if (expanded) {
                  stopPropagation(e);
                  handlePopoverClick(e);
                }
              }}
              sx={{ padding: 0, color: "black" }}
            >
              <Users color={fontColor} />
            </IconButton>
            <Typography
              variant="h6"
              sx={{
                ml: 1,
                fontFamily: "Roboto, sans-serif",
                fontWeight: "bold",
                color: fontColor,
              }}
              onClick={(e) => {
                if (expanded) {
                  stopPropagation(e);
                  handlePopoverClick(e);
                }
              }}
            >
              Persons Involved
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ bgcolor: "#E9EAEC", padding: 2 }}>
          <CardContent
            sx={{
              padding: 0,
              marginBottom: 0,
              "&:last-child": {
                paddingBottom: 0,
              },
            }}
          >
            {personsInvolvedCardData.map((section) => (
              <Box
                key={section.id}
                sx={{
                  border: `2px solid ${documentColor}`,
                  padding: 2,
                  borderRadius: "3px",
                  mb: 2,
                  position: "relative",
                  "&:last-child": {
                    mb: 0,
                  },
                }}
              >
                {showDeleteIcons && personsInvolvedCardData.length > 1 && (
                  <IconButton
                    onClick={() => handleDeleteSection(section.id)}
                    sx={{
                      position: "absolute",
                      top: "4px",
                      right: "4px",
                      padding: "0",
                      color: "red",
                    }}
                  >
                    <CancelIcon sx={{ fontSize: "15px" }} />
                  </IconButton>
                )}
                <Grid container spacing={2} direction="column">
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        border: `2px solid #C7C9CE`,
                        padding: 2,
                        borderRadius: "3px",
                      }}
                    >
                      <InputLabel
                        htmlFor={`jobTitle-${section.id}`}
                        sx={{
                          fontSize: "12px",
                          fontFamily: "Roboto, sans-serif",
                          fontWeight: "bold",
                          color: "#424242",
                        }}
                      >
                        Job Title
                      </InputLabel>
                      <TextField
                        fullWidth
                        id={`jobTitle-${section.id}`}
                        name="jobTitle"
                        value={section.jobTitle}
                        onChange={(e) => handleSectionChange(section.id, e)}
                        variant="outlined"
                        size="small"
                        sx={{
                          bgcolor: "white",
                          borderRadius: "5px",
                          fontFamily: "Roboto, sans-serif",
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box
                      sx={{
                        border: `2px solid #C7C9CE`,
                        padding: 2,
                        borderRadius: "3px",
                      }}
                    >
                      <InputLabel
                        htmlFor={`rolesResponsibilities-${section.id}`}
                        sx={{
                          fontSize: "12px",
                          fontFamily: "Roboto, sans-serif",
                          fontWeight: "bold",
                          color: "#424242",
                        }}
                      >
                        Roles and Responsibilities
                      </InputLabel>
                      <TextareaAutosize
                        id={`rolesResponsibilities-${section.id}`}
                        name="rolesResponsibilities"
                      value={Array.isArray(section.rolesResponsibilities) 
                        ? section.rolesResponsibilities.join(" ").replace(/\.,/g, ". ") 
                        : ''
                    }
                        onChange={(e) => handleSectionChange(section.id, e)}
                        placeholder="Enter roles and responsibilities"
                        minRows={5}
                        style={{
                          minWidth: "95%",
                          fontFamily: "Roboto, sans-serif",
                          maxWidth: "95%",
                          padding: "8px",
                          marginTop: "8px",
                          borderRadius: "5px",
                          borderColor: "grey",
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box
                      sx={{
                        border: `2px solid #C7C9CE`,
                        padding: 2,
                        borderRadius: "3px",
                      }}
                    >
                      <InputLabel
                        htmlFor={`qualificationsExperience-${section.id}`}
                        sx={{
                          fontSize: "12px",
                          fontFamily: "Roboto, sans-serif",
                          fontWeight: "bold",
                          color: "#424242",
                        }}
                      >
                        Qualifications & Experience Required
                      </InputLabel>
                      <TextareaAutosize
                        id={`qualificationsExperience-${section.id}`}
                        name="qualificationsExperience"
                        value={Array.isArray(section.qualificationsExperience) 
                          ? section.qualificationsExperience.join(" ").replace(/\.,/g, ". ") 
                          : ''}
                        onChange={(e) => handleSectionChange(section.id, e)}
                        placeholder="Enter qualifications and experience required"
                        minRows={5}
                        style={{
                          minWidth: "95%",
                          maxWidth: "95%",
                          fontFamily: "Roboto, sans-serif",
                          padding: "8px",
                          marginTop: "8px",
                          borderRadius: "5px",
                          borderColor: "grey",
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            ))}
          </CardContent>
        </AccordionDetails>
      </Accordion>

      <Popover
        id={id}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ p: 3, display: "flex", flexDirection: "column", gap: 2 }}>
          <IconButton
            aria-label="close"
            onClick={handlePopoverClose}
            size="small"
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: "grey.500",
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
          <FormControlLabel
            control={
              <Checkbox
                checked={showDeleteIcons}
                onChange={(e) => setShowDeleteIcons(e.target.checked)}
                sx={{
                  color: documentColor,
                  "&.Mui-checked": {
                    color: documentColor,
                  },
                }}
              />
            }
            label="Edit personnel"
          />
          <Button
            variant="contained"
            sx={{
              bgcolor: documentColor,
              fontFamily: "Roboto, sans-serif",
              color: "black",
              borderRadius: "3px",
              textTransform: "none",
              boxShadow: 3,
              "&:hover": {
                boxShadow: 4,
              },
              "&:active": {
                boxShadow: 2,
              },
            }}
            startIcon={<AddIcon />}
            onClick={handleAddSection}
          >
            Add personnel
          </Button>
        </Box>
      </Popover>
    </Card>
  );
};

export default PersonsInvolvedCard;
