import React, { useState } from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Card,
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    IconButton, Popover, FormControlLabel, Checkbox
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FileText } from 'lucide-react';
import { CircleAlert } from 'lucide-react';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { lighten } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';



const RiskTablesCard = ({ color, fontColor }) => {
    const [risk_oppurtunity, setRiskOppurtunity] = useState(true);
    const [likelihood, setLikelihood] = useState(true);
    const [riskLevelAction, setRiskLevelAction] = useState(true);
    const [hierarchyControl, setHierarchyControl] = useState(true);
    const [consequence, setConsequence] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null); // State for popover anchor
    const [expanded, setExpanded] = useState(false); // Track accordion expansion
    const open = Boolean(anchorEl);
    const id = open ? 'popover-anchor' : undefined;

    const handleIconClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    const handleAccordionChange = (event, isExpanded) => {
        setExpanded(isExpanded);
    };

    const handleCellChange = (e, rowIndex, key) => {
        const updatedData = [...consequenceData];
        updatedData[rowIndex][key] = e.target.innerText;
        setConsequence(updatedData);
    };



    const consequenceData = [
        {
            level: '5',
            category: 'CATASTROPHIC',
            peopleHealthSafety: 'Death, permanent disablement, etc.',
            environmental: 'Permanent environmental damage, Endangered species and habitat destroyed',
            financialAsset: 'Loss >$250K, Irreparable damage to the product, service, outcome',
            communityReputation: 'Severe impact on the community, services and property, International scale negative media attention',
            statutoryLegal: 'Criminal prosecution, Serious litigation, Major fines',
            delay: 'Indefinite Delay or Stoppage',
            conformityQuality: 'The risks can cause damage to the infrastructure that cannot be repaired and affect permanently its function',
        },
        {
            level: '4',
            category: 'MAJOR',
            peopleHealthSafety: 'Serious body injury or illness • LTI, Potential serious LTI, multiple LTIs',
            environmental: 'Major damage to the environment, Long term effect, Damage to protected species or habitat',
            financialAsset: 'Loss $100K - $250K, Serious damage to product',
            communityReputation: 'Major impact on the community, services and property, National public or media negative attention',
            statutoryLegal: 'Prosecution, major fines',
            delay: 'Between 3 - 6 months',
            conformityQuality: 'Damage or effects in the functionality of the infrastructure may occur that can be offset with complex measures, with significant impact on the cost',
        },
        {
            level: '3',
            category: 'MODERATE',
            peopleHealthSafety: 'Injury or Illness requiring casualty treatment - MTI, Potential LTI',
            environmental: 'Serious damage to the environment, Medium term effect, Protected species or habitat involved',
            financialAsset: 'Medium term Loss $25K - $100K, Repairable damage to product',
            communityReputation: 'Serious impact on the community, services and property, State media attention',
            statutoryLegal: 'Prohibition Notice, fines',
            delay: 'Between 1 - 3 months',
            conformityQuality: 'Damage or effects in the functionality of the infrastructure may occur that can be repaired or require increased maintenance assumable for the company',
        },
        {
            level: '2',
            category: 'MINOR',
            peopleHealthSafety: 'Injury/illness requiring first aid treatment - FAI',
            environmental: 'Minor damage to the environment, Within site boundaries',
            financialAsset: 'Loss $5K - $25K, Minor damage to product, service, outcome',
            communityReputation: 'Minor adverse local public or media attention or complaints',
            statutoryLegal: 'Improvement and Infringement notice',
            delay: 'Between 1 week and 4 weeks',
            conformityQuality: 'Damage or effects in the functionality of the infrastructure may occur that can be repaired at low costs for the company, without effect in the maintenance',
        },
        {
            level: '1',
            category: 'INSIGNIFICANT',
            peopleHealthSafety: 'Potential MTI',
            environmental: 'Negligible damage to the environment',
            financialAsset: 'Repairable damage to product, service, outcome',
            communityReputation: 'Community complaints with no corrective action Visit from Regulators with verbal',
            statutoryLegal: 'No comments of OFI',
            delay: 'No impact or less than 1 week',
            conformityQuality: 'No effects are produced on the functionality of the infrastructure',
        },
    ];

    const liklihood_def = [
        {
            letter: "A",
            likelihood: "Almost certain",
            frequency: "Occurs more than once a month",
            description: "The event is expected to occur in most circumstances",
        },
        {
            letter: "B",
            likelihood: "Likely",
            frequency: "Occurs once every month to 1 year",
            description: "The event will probably occur in most circumstances",
        },
        {
            letter: "C",
            likelihood: "Medium",
            frequency: "Occurs once every 1 year to 5 years",
            description: "The event should occur at some times",
        },
        {
            letter: "D",
            likelihood: "Unlikely",
            frequency: "Occurs once every 5 years to 10 years",
            description: "The event could occur at some times",
        },
        {
            letter: "E",
            likelihood: "Rare",
            frequency: "Occurs less than once every 10 years",
            description: "The event may occur only in exceptional circumstances",
        },
    ];

    const risk_level_action_data = [
        {
            riskLevel: "EXTREME (Unacceptable)",
            backgroundColor: "red",
            textColor: 'white',
            action: 'Immediate action required - High level of supervision and monitoring of controls musts be added with senior management responsibility. I.e. Increase inspection monitoring and added to the daily inspection process. A job prestart must be undertaken with all relevant workers, including review of control methods and use of relevant permits to work as applicable.',
        },
        {
            riskLevel: "HIGH (Undesirable)",
            backgroundColor: "#ED7D31",
            textColor: 'white',
            action: "Site management attention needed with safety, quality, and environmental responsibilities communicated and delegated at management level. Take all reasonable steps to eliminate the risk or minimise it by introducing substitution, isolation, or engineering controls as soon as possible.",
        },
        {
            riskLevel: "MODERATE (Tolerable)",
            backgroundColor: "#FFD966",
            textColor: 'black',
            action: "Site management attention needed with safety, quality, and environmental responsibilities communicated and delegated at management level. Take all reasonable steps to eliminate the risk or minimise it by introducing substitution, isolation, or engineering controls as soon as possible.",
        },
        {
            riskLevel: "LOW (Acceptable)",
            backgroundColor: "#A9D08E",
            textColor: 'black',
            action: "Manage by routine procedures such as safe work method statements, communication and consultation processes on a regular basis at individuals. Implementation of control measures should decrease the risk to as low as reasonably practicable.",
        },
    ];

    return (
        <Card sx={{ boxShadow: 3, border: `4px solid ${color}`, backgroundColor: 'white', opacity: 0.9 }}>
            <Accordion expanded={expanded} onChange={handleAccordionChange}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{
                        backgroundColor: color,
                        display: 'flex',
                        alignItems: 'center',
                        height: '60px',
                        '&.Mui-expanded': {
                            height: '50px',
                        },
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                        }}
                    >
                        <IconButton
                            aria-describedby={id}
                            onClick={(e) => {
                                if (expanded) {
                                    stopPropagation(e);
                                    handleIconClick(e);
                                }
                            }}
                            sx={{ padding: 0, color: fontColor }}
                        >
                            <CircleAlert />
                        </IconButton>
                        <Typography
                            variant="h6"
                            sx={{ ml: 1, fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', color: fontColor }}
                            onClick={(e) => {
                                if (expanded) {
                                    stopPropagation(e);
                                    handleIconClick(e);
                                }
                            }}
                        >
                            Risk Tables
                        </Typography>
                    </Box>
                </AccordionSummary>
                <AccordionDetails sx={{ bgcolor: '#E9EAEC', padding: 2 }}>
                    {risk_oppurtunity && (
                        <Box sx={{ border: `2px solid ${color}`, padding: 2, borderRadius: '3px', marginBottom: '16px' }}>
                            <Table sx={{ fontFamily: 'Roboto, sans-serif', fontSize: '11px' }}>
                                <TableHead>
                                    <TableRow>
                                    {/* bgcolor: '#083667', */}
                                        <TableCell colSpan={8} sx={{ textAlign: 'center', padding: '7px', border: '1px solid black', bgcolor: color,  fontWeight: 'bold' }}>
                                            RISK AND OPPORTUNITY RATING TABLES
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={8} sx={{ textAlign: 'center', padding: '5px', border: '1px solid black', bgcolor: color, fontWeight: 'bold' }}>
                                            RISK
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell rowSpan={3} colSpan={3} sx={{ textAlign: 'center', padding: '5px', border: '1px solid black', bgcolor: '#EFEFEF', fontWeight: 'bold' }}>
                                            RISK RATING TABLE
                                        </TableCell>
                                        <TableCell colSpan={5} sx={{ textAlign: 'center', padding: '5px',bgcolor: 'white', color: 'red', border: '1px solid black', fontWeight: 'bold' }}>
                                            CONSEQUENCE
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ bgcolor: '#d9d9d9', padding: '5px', border: '1px solid black', textAlign: 'center' }}>Insignificant</TableCell>
                                        <TableCell sx={{ bgcolor: '#d9d9d9', padding: '5px', border: '1px solid black', textAlign: 'center' }}>Minor</TableCell>
                                        <TableCell sx={{ bgcolor: '#d9d9d9', padding: '5px', border: '1px solid black', textAlign: 'center' }}>Moderate</TableCell>
                                        <TableCell sx={{ bgcolor: '#d9d9d9', padding: '5px', border: '1px solid black', textAlign: 'center' }}>Major</TableCell>
                                        <TableCell sx={{ bgcolor: '#d9d9d9', padding: '5px', border: '1px solid black', textAlign: 'center' }}>Catastrophic</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ bgcolor: '#d9d9d9', padding: '5px', border: '1px solid black', textAlign: 'center' }}>1</TableCell>
                                        <TableCell sx={{ bgcolor: '#d9d9d9', padding: '5px', border: '1px solid black', textAlign: 'center' }}>2</TableCell>
                                        <TableCell sx={{ bgcolor: '#d9d9d9', padding: '5px', border: '1px solid black', textAlign: 'center' }}>3</TableCell>
                                        <TableCell sx={{ bgcolor: '#d9d9d9', padding: '5px', border: '1px solid black', textAlign: 'center' }}>4</TableCell>
                                        <TableCell sx={{ bgcolor: '#d9d9d9', padding: '5px', border: '1px solid black', textAlign: 'center' }}>5</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell rowSpan={5} sx={{ textAlign: 'center',bgcolor: 'white', padding: '5px', border: '1px solid black', fontWeight: 'bold', borderRadius: '0 0 0 3px' }}>
                                            LIKELIHOOD
                                        </TableCell>
                                        <TableCell sx={{ bgcolor: '#d9d9d9', padding: '5px', border: '1px solid black', textAlign: 'center' }}>Almost Certain</TableCell>
                                        <TableCell sx={{ bgcolor: '#d9d9d9', padding: '5px', border: '1px solid black', textAlign: 'center' }}>A</TableCell>
                                        <TableCell sx={{ bgcolor: '#ffc000', padding: '5px', border: '1px solid black', textAlign: 'center' }}>H-11</TableCell>
                                        <TableCell sx={{ bgcolor: '#ffc000', padding: '5px', border: '1px solid black', textAlign: 'center' }}>H-16</TableCell>
                                        <TableCell sx={{ bgcolor: 'red', padding: '5px', border: '1px solid black', color: 'white', textAlign: 'center' }}>E-20</TableCell>
                                        <TableCell sx={{ bgcolor: 'red', padding: '5px', border: '1px solid black', color: 'white', textAlign: 'center' }}>E-23</TableCell>
                                        <TableCell sx={{ bgcolor: 'red', padding: '5px', border: '1px solid black', color: 'white', textAlign: 'center' }}>E-25</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ bgcolor: '#d9d9d9', padding: '5px', border: '1px solid black', textAlign: 'center' }}>Likely</TableCell>
                                        <TableCell sx={{ bgcolor: '#d9d9d9', padding: '5px', border: '1px solid black', textAlign: 'center' }}>B</TableCell>
                                        <TableCell sx={{ bgcolor: '#ffd966', padding: '5px', border: '1px solid black', textAlign: 'center' }}>M-7</TableCell>
                                        <TableCell sx={{ bgcolor: '#ffc000', padding: '5px', border: '1px solid black', textAlign: 'center' }}>H-12</TableCell>
                                        <TableCell sx={{ bgcolor: '#ffc000', padding: '5px', border: '1px solid black', textAlign: 'center' }}>H-17</TableCell>
                                        <TableCell sx={{ bgcolor: 'red', padding: '5px', border: '1px solid black', color: 'white', textAlign: 'center' }}>E-21</TableCell>
                                        <TableCell sx={{ bgcolor: 'red', padding: '5px', border: '1px solid black', color: 'white', textAlign: 'center' }}>E-24</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ bgcolor: '#d9d9d9', padding: '5px', border: '1px solid black', textAlign: 'center' }}>Possible</TableCell>
                                        <TableCell sx={{ bgcolor: '#d9d9d9', padding: '5px', border: '1px solid black', textAlign: 'center' }}>C</TableCell>
                                        <TableCell sx={{ bgcolor: '#a9d08e', padding: '5px', border: '1px solid black', textAlign: 'center' }}>L-4</TableCell>
                                        <TableCell sx={{ bgcolor: '#ffd966', padding: '5px', border: '1px solid black', textAlign: 'center' }}>M-8</TableCell>
                                        <TableCell sx={{ bgcolor: '#ffc000', padding: '5px', border: '1px solid black', textAlign: 'center' }}>H-13</TableCell>
                                        <TableCell sx={{ bgcolor: 'red', padding: '5px', border: '1px solid black', color: 'white', textAlign: 'center' }}>E-18</TableCell>
                                        <TableCell sx={{ bgcolor: 'red', padding: '5px', border: '1px solid black', color: 'white', textAlign: 'center' }}>E-22</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ bgcolor: '#d9d9d9', padding: '5px', border: '1px solid black', textAlign: 'center' }}>Unlikely</TableCell>
                                        <TableCell sx={{ bgcolor: '#d9d9d9', padding: '5px', border: '1px solid black', textAlign: 'center' }}>D</TableCell>
                                        <TableCell sx={{ bgcolor: '#a9d08e', padding: '5px', border: '1px solid black', textAlign: 'center' }}>L-2</TableCell>
                                        <TableCell sx={{ bgcolor: '#a9d08e', padding: '5px', border: '1px solid black', textAlign: 'center' }}>L-5</TableCell>
                                        <TableCell sx={{ bgcolor: '#ffd966', padding: '5px', border: '1px solid black', textAlign: 'center' }}>M-9</TableCell>
                                        <TableCell sx={{ bgcolor: '#ffc000', padding: '5px', border: '1px solid black', textAlign: 'center' }}>H-14</TableCell>
                                        <TableCell sx={{ bgcolor: 'red', padding: '5px', border: '1px solid black', color: 'white', textAlign: 'center' }}>E-19</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ bgcolor: '#d9d9d9', padding: '5px', border: '1px solid black', textAlign: 'center' }}>Rare</TableCell>
                                        <TableCell sx={{ bgcolor: '#d9d9d9', padding: '5px', border: '1px solid black', textAlign: 'center' }}>E</TableCell>
                                        <TableCell sx={{ bgcolor: '#a9d08e', padding: '5px', border: '1px solid black', textAlign: 'center' }}>L-1</TableCell>
                                        <TableCell sx={{ bgcolor: '#a9d08e', padding: '5px', border: '1px solid black', textAlign: 'center' }}>L-3</TableCell>
                                        <TableCell sx={{ bgcolor: '#ffd966', padding: '5px', border: '1px solid black', textAlign: 'center' }}>M-6</TableCell>
                                        <TableCell sx={{ bgcolor: '#ffc000', padding: '5px', border: '1px solid black', textAlign: 'center' }}>H-10</TableCell>
                                        <TableCell sx={{ bgcolor: '#ffc000', padding: '5px', border: '1px solid black', textAlign: 'center', borderRadius: '0 0 3px 0' }}>H-15</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    )}
                    {likelihood && (
                        <Box sx={{ border: `2px solid ${color}`, padding: 2, borderRadius: '3px', marginBottom: '16px' }}>
                            <Table size="small" aria-label="likelihood definition table" sx={{ fontFamily: 'Roboto, sans-serif', fontSize: '11px' }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={8} sx={{ textAlign: 'center', padding: '7px', border: '1px solid black', bgcolor: color, fontWeight: 'bold' }}>
                                            LIKELIHOOD DEFINITION SUMMARY
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={3} sx={{ textAlign: 'center', bgcolor: '#d9d9d9', fontWeight: 'bold', borderBottom: '1px solid black', borderLeft: '1px solid black' }}>
                                            Probability (Frequency)
                                        </TableCell>
                                        <TableCell colSpan={1} sx={{ textAlign: 'center', bgcolor: '#d9d9d9', fontWeight: 'bold', borderBottom: '1px solid black', borderRight: '1px solid black' }}>
                                            Description
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {liklihood_def.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell sx={{ fontWeight: 'bold', textAlign: 'center', border: '1px solid black' }}>{row.letter}</TableCell>
                                            <TableCell sx={{ border: '1px solid black' }}>{row.likelihood}</TableCell>
                                            <TableCell sx={{ border: '1px solid black' }}>{row.frequency}</TableCell>
                                            <TableCell sx={{ border: '1px solid black' }}>{row.description}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    )}
                    {riskLevelAction && (
                        <Box sx={{ border: `2px solid ${color}`, padding: 2, borderRadius: '3px', marginBottom: '16px' }}>
                            <Table size="small" aria-label="risk level action table" sx={{ fontFamily: 'Roboto, sans-serif', fontSize: '11px' }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={8} sx={{ textAlign: 'center', padding: '7px', border: '1px solid black', bgcolor: color, fontWeight: 'bold' }}>
                                            RISK LEVEL ACTION TABLE
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {risk_level_action_data.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell
                                                sx={{
                                                    backgroundColor: row.backgroundColor,
                                                    color: row.textColor,
                                                    textAlign: "center",
                                                    border: '1px solid black'
                                                }}
                                            >
                                                {row.riskLevel}
                                            </TableCell>
                                            <TableCell sx={{ border: '1px solid black' }}>{row.action}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    )}
                    {hierarchyControl && (
                        <Box sx={{ border: `2px solid ${color}`, padding: 2, borderRadius: "3px", marginBottom: '16px' }}>
                            <Table size="small" aria-label="hierarchy of controls table" sx={{ fontFamily: "Roboto, sans-serif", fontSize: "12px", borderCollapse: 'collapse' }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={3} sx={{ textAlign: "center", bgcolor: color,fontWeight: "bold", border: "0.5pt solid #161616", padding: '5px' }}>
                                            HIERARCHY of CONTROLS
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell rowSpan={2} sx={{ textAlign: "center", verticalAlign: "middle", bgcolor: "#a9d08e", border: "0.5pt solid #161616", padding: '5px' }}>
                                            Highest
                                        </TableCell>
                                        <TableCell sx={{ textAlign: "center",fontWeight: 'bold', verticalAlign: "middle", bgcolor: lighten(color, 0.3), border: "0.5pt solid #161616", padding: '5px' }}>
                                            LEVEL 1
                                        </TableCell>
                                        <TableCell rowSpan={2} sx={{ textAlign: "center", verticalAlign: "middle", bgcolor: "#a9d08e", border: "0.5pt solid #161616", padding: '5px' }}>
                                            Most
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ textAlign: "center", verticalAlign: "middle", bgcolor: lighten(color, 0.3), border: "0.5pt solid #161616", padding: '5px' }}>
                                            Eliminate the Hazard
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={3} sx={{ backgroundColor: "white", padding: '4px', border: "none" }} />
                                    </TableRow>
                                    <TableRow>
                                        <TableCell rowSpan={4} sx={{ textAlign: "center", verticalAlign: "middle", bgcolor: "#ffd966", border: "0.5pt solid #161616", padding: '5px' }}>
                                            Level of Health and Safety Protection
                                        </TableCell>
                                        <TableCell sx={{ textAlign: "center",fontWeight:'bold', verticalAlign: "middle",bgcolor: lighten(color, 0.3), border: "0.5pt solid #161616", padding: '5px' }}>
                                            LEVEL 2
                                        </TableCell>
                                        <TableCell rowSpan={4} sx={{ textAlign: "center", verticalAlign: "middle", bgcolor: "#ffd966", border: "0.5pt solid #161616", padding: '5px' }}>
                                            Reliability of Control Measures
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ textAlign: "center", verticalAlign: "middle", bgcolor: lighten(color, 0.3), border: "0.5pt solid #161616", padding: '5px' }}>
                                            Substitute the hazard with something safer
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ textAlign: "center", verticalAlign: "middle", bgcolor: lighten(color, 0.3), border: "0.5pt solid #161616", padding: '5px' }}>
                                            Isolate the hazard from people
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ textAlign: "center", verticalAlign: "middle", bgcolor: lighten(color, 0.3), border: "0.5pt solid #161616", padding: '5px' }}>
                                            Reduce the risk through engineering controls
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={3} sx={{ backgroundColor: "white", padding: '4px', border: "none" }} />
                                    </TableRow>
                                    <TableRow>
                                        <TableCell rowSpan={3} sx={{ textAlign: "center", verticalAlign: "middle", bgcolor: "red", color: "white", fontWeight: "bold", border: "0.5pt solid #161616", padding: '5px' }}>
                                            Lowest
                                        </TableCell>
                                        <TableCell sx={{ textAlign: "center", verticalAlign: "middle", bgcolor: lighten(color, 0.3), border: "0.5pt solid #161616", padding: '5px', fontWeight: 'bold' }}>
                                            LEVEL 3
                                        </TableCell>
                                        <TableCell rowSpan={3} sx={{ textAlign: "center", verticalAlign: "middle", bgcolor: "red", color: "white", fontWeight: "bold", border: "0.5pt solid #161616", padding: '5px' }}>
                                            Least
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ textAlign: "center", verticalAlign: "middle",bgcolor: lighten(color, 0.3), border: "0.5pt solid #161616", padding: '5px' }}>
                                            Reduce the exposure to the hazard using administrative actions
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ textAlign: "center", verticalAlign: "middle", bgcolor: lighten(color, 0.3), border: "0.5pt solid #161616", padding: '5px' }}>
                                            Use personal protective equipment
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    )}
                    {consequence && (
                        <Box sx={{ border: `2px solid ${color}`, overflowX: 'auto',  padding: 2, borderRadius: "3px" }}>
                            <Table size="small" aria-label="consequence definition table" sx={{ fontFamily: "Roboto, sans-serif",  borderCollapse: 'collapse' }}>
                                <TableHead>
                                    <TableRow >
                                        <TableCell colSpan={9} sx={{ textAlign: "center", bgcolor:color, fontWeight: "bold", border: "0.5pt solid #161616", padding: '5px' }}>
                                            CONSEQUENCE DEFINITION SUMMARY
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ padding: '5px', fontWeight: 'bold', borderTop: '0.5pt solid #161616', borderBottom: '0.5pt solid #161616', borderLeft: '0.5pt solid #161616', backgroundColor: '#d9d9d9' }}>
                                            &nbsp;
                                        </TableCell>
                                        <TableCell sx={{ padding: '5px', fontWeight: 'bold', borderTop: '0.5pt solid #161616', borderBottom: '0.5pt solid #161616', borderRight: '0.5pt solid #161616', backgroundColor: '#d9d9d9' }}>
                                            &nbsp;
                                        </TableCell>
                                        <TableCell sx={{ padding: '5px', fontWeight: 'bold', border: '0.5pt solid #161616', backgroundColor: '#d9d9d9' }}>
                                            PEOPLE/HEALTH AND SAFETY
                                        </TableCell>
                                        <TableCell sx={{ padding: '5px', fontWeight: 'bold', border: '0.5pt solid #161616', backgroundColor: '#d9d9d9' }}>
                                            ENVIRONMENTAL
                                        </TableCell>
                                        <TableCell sx={{ padding: '5px', fontWeight: 'bold', border: '0.5pt solid #161616', backgroundColor: '#d9d9d9' }}>
                                            FINANCIAL ASSET
                                        </TableCell>
                                        <TableCell sx={{ padding: '5px', fontWeight: 'bold', border: '0.5pt solid #161616', backgroundColor: '#d9d9d9' }}>
                                            COMMUNITY/REPUTATION
                                        </TableCell>
                                        <TableCell sx={{ padding: '5px', fontWeight: 'bold', border: '0.5pt solid #161616', backgroundColor: '#d9d9d9' }}>
                                            STATUTORY/LEGAL
                                        </TableCell>
                                        <TableCell sx={{ padding: '5px', fontWeight: 'bold', border: '0.5pt solid #161616', backgroundColor: '#d9d9d9' }}>
                                            DELAY
                                        </TableCell>
                                        <TableCell sx={{ padding: '5px', fontWeight: 'bold', border: '0.5pt solid #161616', backgroundColor: '#d9d9d9' }}>
                                            CONFORMITY/QUALITY
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
            {consequenceData.map((item, rowIndex) => (
                <TableRow key={item.level}>
                    <TableCell
                        contentEditable
                        suppressContentEditableWarning
                        onBlur={(e) => handleCellChange(e, rowIndex, 'level')}
                        sx={{ padding: '10px', border: '0.5pt solid #161616', textAlign: 'center', verticalAlign: 'middle', fontWeight: 'bold', backgroundColor: '#d9d9d9' }}
                    >
                        {item.level}
                    </TableCell>
                    <TableCell
                        contentEditable
                        suppressContentEditableWarning
                        onBlur={(e) => handleCellChange(e, rowIndex, 'category')}
                        sx={{ padding: '5px', border: '0.5pt solid #161616', textAlign: 'center', verticalAlign: 'middle', fontWeight: 'bold', backgroundColor: '#d9d9d9' }}
                    >
                        {item.category}
                    </TableCell>
                    <TableCell
                        contentEditable
                        suppressContentEditableWarning
                        onBlur={(e) => handleCellChange(e, rowIndex, 'peopleHealthSafety')}
                        sx={{ padding: '5px', border: '0.5pt solid #161616', textAlign: 'center', verticalAlign: 'middle' }}
                    >
                        {item.peopleHealthSafety}
                    </TableCell>
                    <TableCell
                        contentEditable
                        suppressContentEditableWarning
                        onBlur={(e) => handleCellChange(e, rowIndex, 'environmental')}
                        sx={{ padding: '5px', border: '0.5pt solid #161616', textAlign: 'center', verticalAlign: 'middle' }}
                    >
                        {item.environmental}
                    </TableCell>
                    <TableCell
                        contentEditable
                        suppressContentEditableWarning
                        onBlur={(e) => handleCellChange(e, rowIndex, 'financialAsset')}
                        sx={{ padding: '5px', border: '0.5pt solid #161616', textAlign: 'center', verticalAlign: 'middle' }}
                    >
                        {item.financialAsset}
                    </TableCell>
                    <TableCell
                        contentEditable
                        suppressContentEditableWarning
                        onBlur={(e) => handleCellChange(e, rowIndex, 'communityReputation')}
                        sx={{ padding: '5px', border: '0.5pt solid #161616', textAlign: 'center', verticalAlign: 'middle' }}
                    >
                        {item.communityReputation}
                    </TableCell>
                    <TableCell
                        contentEditable
                        suppressContentEditableWarning
                        onBlur={(e) => handleCellChange(e, rowIndex, 'statutoryLegal')}
                        sx={{ padding: '5px', border: '0.5pt solid #161616', textAlign: 'center', verticalAlign: 'middle' }}
                    >
                        {item.statutoryLegal}
                    </TableCell>
                    <TableCell
                        contentEditable
                        suppressContentEditableWarning
                        onBlur={(e) => handleCellChange(e, rowIndex, 'delay')}
                        sx={{ padding: '5px', border: '0.5pt solid #161616', textAlign: 'center', verticalAlign: 'middle' }}
                    >
                        {item.delay}
                    </TableCell>
                    <TableCell
                        contentEditable
                        suppressContentEditableWarning
                        onBlur={(e) => handleCellChange(e, rowIndex, 'conformityQuality')}
                        sx={{ padding: '5px', border: '0.5pt solid #161616', textAlign: 'center', verticalAlign: 'middle' }}
                    >
                        {item.conformityQuality}
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
                            </Table>
                        </Box>
                    )}
                </AccordionDetails>
            </Accordion>

            {/* Popover for Checkbox controls */}
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Box sx = {{display: 'flex', alignContent: 'start', alignItems: 'center'}}>
                    <IconButton
                        onClick={() => setRiskOppurtunity(!risk_oppurtunity)}
                        sx={{
                            color: color,
                            '&:hover': {
                                color: color,
                            }
                        }}
                    >
                        {!risk_oppurtunity ? (
                            <VisibilityOff sx={{ color: color }} />
                        ) : (
                            <Visibility sx={{ color: color }} />
                        )}
                    </IconButton>
                    <Typography>Risk and opportunity rating tables</Typography>
                    </Box>
                    <Box sx = {{display: 'flex', alignContent: 'start', alignItems: 'center'}}>
                    <IconButton
                        onClick={() => setLikelihood(!likelihood)}
                        sx={{
                            color: color,
                            '&:hover': {
                                color: color,
                            }
                        }}
                    >
                        {!likelihood ? (
                            <VisibilityOff sx={{ color: color }} />
                        ) : (
                            <Visibility sx={{ color: color }} />
                        )}
                    </IconButton>
                    <Typography>Likelihood definition summary</Typography>
                    </Box>
                    <Box sx = {{display: 'flex', alignContent: 'start', alignItems: 'center'}}>
                    <IconButton
                        onClick={() => setRiskLevelAction(!riskLevelAction)}
                        sx={{
                            color: color,
                            '&:hover': {
                                color: color,
                            }
                        }}
                    >
                        {!riskLevelAction ? (
                            <VisibilityOff sx={{ color: color }} />
                        ) : (
                            <Visibility sx={{ color: color }} />
                        )}
                    </IconButton>
                    <Typography>Risk level action table</Typography>
                    </Box>
                    <Box sx = {{display: 'flex', alignContent: 'start', alignItems: 'center'}}>
                    <IconButton
                        onClick={() => setHierarchyControl(!hierarchyControl)}
                        sx={{
                            color: color,
                            '&:hover': {
                                color: color,
                            }
                        }}
                    >
                        {!hierarchyControl ? (
                            <VisibilityOff sx={{ color: color }} />
                        ) : (
                            <Visibility sx={{ color: color }} />
                        )}
                    </IconButton>
                    <Typography>Hierarchy of controls</Typography>
                    </Box>
                    <Box sx = {{display: 'flex', alignContent: 'start', alignItems: 'center'}}>
                    <IconButton
                        onClick={() => setConsequence(!consequence)}
                        sx={{
                            color: color,
                            '&:hover': {
                                color: color,
                            }
                        }}
                    >
                        {!consequence ? (
                            <VisibilityOff sx={{ color: color }} />
                        ) : (
                            <Visibility sx={{ color: color }} />
                        )}
                    </IconButton>
                    <Typography>Consequence definition summary</Typography>
                    </Box>

                </Box>
            </Popover>
        </Card>
    );
};

export default RiskTablesCard;