// src/App.js
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import LoginPage from "./pages/LoginPage";
import LandingPage from "./pages/LandingPage";
import UserDashboard from "./pages/UserDashboard";
import SettingsPage from "./pages/SettingsPage";
import AllDocuments from "./pages/AllDocuments";
import UserSignup from "./pages/UserSignUp";
import AdminDashboard from "./pages/AdminDashbaord";
import UserRequests from "./pages/UserRequests";
import Payments from "./pages/PaymentsPage";
import ApprovedRequests from "./pages/ApprovedRequests";
import DeclineRequests from "./pages/DeclineRequests";
import AdminLayout from "./components/AdminLayout";
import UserLayout from "./components/UserLayout";
import UpdateDocument from "./pages/UpdateDocument";
import DisplayAllUsers from "./pages/DisplayAllUsers";
import { AuthProvider, useAuth } from "./context/AuthContext";
import { UserProvider } from "./context/UserContext";
import { FormProvider } from "./context/FormContext";
import UserDocuments from "./pages/UserDocuments";
import BuyToken from "./pages/BuyToken";
import Guide from "./pages/Guide";
import HomePage from "./pages/HomePage";
import FaviconManager from './components/FaviconManager';
import ForgotPassword from "./pages/ForgotPassword";
import Feedback from "./pages/Feedback";
import Agreement from "./pages/Agreement";
import ColorPickerAndApiModal from "./components/ColorPickerAndApiModal";

const stripePromise = loadStripe(
  "pk_test_51PvET9Rwl8bU7EBdaXBdwcOS1T3v9J2YIKlOyPquU2pm7bWmcPibGU1fzX862C3vZQ45i4G7zdAnTWqPxp2G91HW00zQzc1QIh"
);

// A wrapper component for routes that should be accessible by non-authenticated users
const PublicRoute = ({ children }) => {
  const { isAuthenticated, loading } = useAuth();

  if (loading) return <div>Loading...</div>;

  // Redirect logged-in users to their dashboard
  return !isAuthenticated ? children : <Navigate to="/user-dashboard" />;
};

// A wrapper component for routes that should be protected (accessible only by authenticated users)
const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, loading } = useAuth();

  if (loading) return <div>Loading...</div>;

  return isAuthenticated ? children : <Navigate to="/" />;
};

function App() {
  return (
    <Elements stripe={stripePromise}>
      <AuthProvider>
        <UserProvider>
          <FormProvider>
            <Router>
              <FaviconManager />
              <Routes>
                {/* Public Routes */}
                <Route
                  path="/"
                  element={
                    <PublicRoute>
                      <HomePage />
                    </PublicRoute>
                  }
                />
                <Route
                  path="/login"
                  element={
                    <PublicRoute>
                      <LoginPage />
                    </PublicRoute>
                  }
                />
                <Route
                  path="/home"
                  element={
                    <ProtectedRoute>
                      <LandingPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/update-document/:documentId"
                  element={
                    <ProtectedRoute>
                      <UpdateDocument />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/signup"
                  element={
                    <PublicRoute>
                      <UserSignup />
                    </PublicRoute>
                  }
                />
                <Route
                  path="/forgot-password"
                  element={
                    <PublicRoute>
                      <ForgotPassword />
                    </PublicRoute>
                  }
                />

                {/* Protected Routes */}
                <Route path="/payment" element={<Payments />} />

                {/* User Routes */}
                <Route
                  path="/user-dashboard"
                  element={
                    <ProtectedRoute>
                      <UserLayout>
                        <UserDashboard />
                      </UserLayout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/user-dashboard/all-documents"
                  element={
                    <ProtectedRoute>
                      <UserLayout>
                        <AllDocuments />
                      </UserLayout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/user-dashboard/buy-token"
                  element={
                    <ProtectedRoute>
                      <UserLayout>
                        <BuyToken />
                      </UserLayout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/user-dashboard/guide"
                  element={
                    <ProtectedRoute>
                      <UserLayout>
                        <Guide />
                      </UserLayout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/user-dashboard/feedback"
                  element={
                    <ProtectedRoute>
                      <UserLayout>
                        <Feedback />
                      </UserLayout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/user-dashboard/agreements"
                  element={
                    <ProtectedRoute>
                      <UserLayout>
                        <Agreement />
                      </UserLayout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/user-dashboard/settings"
                  element={
                    <ProtectedRoute>
                      <UserLayout>
                        <SettingsPage />
                      </UserLayout>
                    </ProtectedRoute>
                  }
                />

                {/* Admin Routes with common layout */}
                <Route
                  path="/admin-dashboard"
                  element={
                    <ProtectedRoute>
                      <AdminLayout>
                        <AdminDashboard />
                      </AdminLayout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin-dashboard/all-users"
                  element={
                    <ProtectedRoute>
                      <AdminLayout>
                        <DisplayAllUsers />
                      </AdminLayout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin/users/:userId/documents"
                  element={
                    <ProtectedRoute>
                      <AdminLayout>
                        <UserDocuments />
                      </AdminLayout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin-dashboard/requests"
                  element={
                    <ProtectedRoute>
                      <AdminLayout>
                        <UserRequests />
                      </AdminLayout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin-dashboard/approve-requests"
                  element={
                    <ProtectedRoute>
                      <AdminLayout>
                        <ApprovedRequests />
                      </AdminLayout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin-dashboard/decline-requests"
                  element={
                    <ProtectedRoute>
                      <AdminLayout>
                        <DeclineRequests />
                      </AdminLayout>
                    </ProtectedRoute>
                  }
                />
              </Routes>
            </Router>
          </FormProvider>
        </UserProvider>
      </AuthProvider>
    </Elements>
  );
}

export default App;
