import React, { createContext, useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from './AuthContext';

export const FormContext = createContext();

export const FormProvider = ({ children }) => {
  const [swmsAutocompleteAppVisibility, setSwmsAutocompleteAppVisibility] = useState(true);
  const [landingPageVisibility, setLandingPageVisibility] = useState(false);
  const [isPageMode, setIsPageMode] = useState(true);
  const [openColorDialog, setOpenColorDialog] = useState(false);
  const [currentDocument, setCurrentDocument] = useState(null);
  const [ProjectDetailsData, setProjectDetailsData] = useState({
    swmsTitle: '',
    swmsNo: '',
    workLocation: '',
    revNo: '',
    projectName: '',
    date: '',
    contractorName: '',
    abn: '',
    address: '',
    documentTitle: ''
  });

  const [highLevelRisksData, setHighLevelRisksData] = useState([]);
  const [riskManagementScheduleData, setRiskManagementScheduleData] = useState([]);
  const [personsInvolvedCardData, setPersonsInvolvedCardData] = useState([]);
  const [constructionPlantEquipmentData, setConstructionPlantEquipmentData] = useState([]);
  const [legislativeListData, setLegislativeListData] = useState([]);
  const [ppeCardData, setPPECardData] = useState([]);
  const [permitsData, setPermitsData] = useState([]);
  const [inductionSignOnData, setInductionSignOnData] = useState({ rowsPage1: [] });
  const [peopleInvolvedData, setPeopleInvolvedData] = useState({ rowsPage1: [] });
  const [formReviewerData, setFormReviewerData] = useState({ rows_page1: [] });
  const [riskTablesData, setRiskTablesData] = useState({
    risk_oppurtunity: true,
    likelihood: true,
    riskLevelAction: true,
    hierarchyControl: true,
    consequence: true,
  });
  const [documentColor, setDocumentColor] = useState('#f5a623');
  const [documentFontColor, setDocumentFontColor] = useState('#000000');
  const [documentTitle, setDocumentTitle] = useState('');
  const [formMetadata, setFormMetadata] = useState({
    taskDetails: '',
    locationDetails: '',
    includeTasks: '',
    excludeTasks: '',
    numberOfActivities: 16,
    selectedState: '',
  });

  const { authToken } = useAuth();

  const handleProjectDetailsChange = (e) => {
    const { name, value } = e.target;
    setProjectDetailsData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleHighLevelRiskChange = useCallback((updatedRisks) => {
    // console.log('Updating risks:', updatedRisks);
    setHighLevelRisksData(prevRisks => {
      const newRisks = updatedRisks.map((risk, index) => ({
        ...risk,
        id: risk.id || index,
      }));
      // console.log('New risks state:', newRisks);
      return newRisks;
    });
  }, []);

  const handleRiskManagementScheduleChange = (updatedSchedule) => {
    setRiskManagementScheduleData(updatedSchedule);
  };

  const handlePersonsInvolvedCardChange = (updatedPersons) => {
    setPersonsInvolvedCardData(updatedPersons);
  };

  const handleConstructionPlantEquipmentChange = (updatedConstructionPlantEquipment) => {
    setConstructionPlantEquipmentData(updatedConstructionPlantEquipment);
  };

  const handleLegislativeListChange = (updatedList) => {
    setLegislativeListData(updatedList);
  };

  const handlePPECardChange = (updatedPPE) => {
    setPPECardData(updatedPPE);
  };

  const handlePermitsChange = (updatedPermits) => {
    setPermitsData(updatedPermits);
  };

  const handleInductionSignOnChange = (updatedInduction) => {
    setInductionSignOnData(updatedInduction);
  };

  const handlePeopleInvolvedChange = (updatedPeople) => {
    setPeopleInvolvedData(updatedPeople);
  };

  const handleFormReviewerChange = useCallback((updatedReviewer) => {
    setFormReviewerData(updatedReviewer);
  }, []);

  const handleRiskTablesChange = (updatedTables) => {
    setRiskTablesData(updatedTables);
  };

  const resetFormData = () => {
    setDocumentTitle('');
    setFormMetadata({
      taskDetails: '',
      locationDetails: '',
      includeTasks: '',
      excludeTasks: '',
      numberOfActivities: 14,
      selectedState: '',
    });
    setProjectDetailsData({
      swmsTitle: '',
      swmsNo: '',
      workLocation: '',
      revNo: '',
      projectName: '',
      date: '',
      contractorName: '',
      abn: '',
      address: '',
      documentTitle: ''
    });
    setHighLevelRisksData([]);
    setRiskManagementScheduleData([]);
    setPersonsInvolvedCardData([]);
    setConstructionPlantEquipmentData([]);
    setLegislativeListData([]);
    setPPECardData([]);
    setPermitsData([]);
    setInductionSignOnData({ rowsPage1: [] });
    setPeopleInvolvedData({ rowsPage1: [] });
    setFormReviewerData({ rows_page1: [] });
    setRiskTablesData({
      risk_oppurtunity: true,
      likelihood: true,
      riskLevelAction: true,
      hierarchyControl: true,
      consequence: true,
    });
    setDocumentColor('#f5a623'); 
    setDocumentFontColor('#000000');
  };

  const saveFormData = async (documentData) => {
    if (authToken) {
      try {
        // Create a new object that includes both document data and form metadata
        const dataToSave = {
          ...documentData,
          task_details: formMetadata.taskDetails || '',
          location_details: formMetadata.locationDetails || '',
          include_tasks: formMetadata.includeTasks || '',
          exclude_tasks: formMetadata.excludeTasks || '',
          number_of_activities: parseInt(formMetadata.numberOfActivities) || 16,
          selected_state: formMetadata.selectedState || '',
        };

        console.log('Data being saved:', dataToSave); // Add this for debugging

        const response = await axios.post('https://www.icromm.com/api/documents', dataToSave, {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        });

        return response.data;
      } catch (error) {
        console.error('Error saving form data:', error);
        throw error;
      }
    } else {
      console.error('No auth token available');
      throw new Error('Authentication required');
    }
  };

  const loadGeneratedData = async (q1, q2, q3, q4, q5, q6, q7) => {
    setHighLevelRisksData(q1 || []);
    setRiskManagementScheduleData(q2 || []);
    setPersonsInvolvedCardData(q3 || []);
    setPPECardData(q4 || []);
    setPermitsData(q5 || []);
    setLegislativeListData(q6 || []);
    setConstructionPlantEquipmentData(q7 || []);

    // console.log("q4", q4)
  };


  const setDocumentData = useCallback((data) => {
    setCurrentDocument(data);
    setDocumentTitle(data.document_title || '');
    setProjectDetailsData(data.project_details || {});
    setHighLevelRisksData(data.high_level_risks?.risks || []);
    setRiskManagementScheduleData(data.risk_management_schedule?.sections || []);
    setPersonsInvolvedCardData(data.persons_involved_card?.sections || []);
    
    // Format construction plant equipment data
    const formattedConstructionPlantEquipment = data.construction_plant_equipment?.sections?.map(section => ({
      ...section,
      plant_and_equipment: Array.isArray(section.plant_and_equipment)
        ? section.plant_and_equipment
        : [section.plant_and_equipment || ""],
      safety_consideration: Array.isArray(section.safety_consideration)
        ? section.safety_consideration
        : typeof section.safety_consideration === 'string'
          ? section.safety_consideration
              .split(/[,\n]/)  // Split by comma or newline
              .map(item => item.trim())
              .filter(item => item !== '')
              .map(item => item.startsWith('- ') ? item : `- ${item}`)
          : [],
      standards_and_regulations: Array.isArray(section.standards_and_regulations)
        ? section.standards_and_regulations
        : section.standards_and_regulations?.split('\n')
            .map(item => item.trim())
            .filter(item => item !== '')
            .map(item => item.startsWith('- ') ? item.substring(2) : item) || [],
    })) || [];
    
    setConstructionPlantEquipmentData(formattedConstructionPlantEquipment);
    
    setLegislativeListData(data.legislative_list?.items || []);
    setPPECardData(data.ppe_card?.ppeList || []);
    setInductionSignOnData(data.induction_sign_on || { rowsPage1: [] });
    setPeopleInvolvedData(data.people_involved || {});
    setFormReviewerData(data.form_reviewer || {});
    setRiskTablesData(data.risk_tables || {});
    const newColor = data.color || '#f5a623';
    setDocumentColor(newColor);

    // Format permits data to ensure proper line breaks
    const formattedPermits = data.permits?.map(permit => ({
      ...permit,
      howToAcquire: Array.isArray(permit.howToAcquire) 
        ? permit.howToAcquire
        : typeof permit.howToAcquire === 'string'
          ? permit.howToAcquire.split(',').map(item => item.trim())
          : [],
      whyRequired: permit.whyRequired || ''
    })) || [];
    
    setPermitsData(formattedPermits);

    // console.log("ppe_card", data.ppe_card)
    // console.log('Document color set to:', newColor);
    setFormMetadata({
      taskDetails: data.task_details || '',
      locationDetails: data.location_details || '',
      includeTasks: data.include_tasks || '',
      excludeTasks: data.exclude_tasks || '',
      numberOfActivities: parseInt(data.number_of_activities) || 16,
      selectedState: data.selected_state || '',
    });
  }, []);

  useEffect(() => {
    // console.log('HighLevelRisksData updated:', highLevelRisksData);
  }, [highLevelRisksData]);

  return (
    <FormContext.Provider
      value={{
        isPageMode,
        setIsPageMode,
        landingPageVisibility,
        setLandingPageVisibility,
        openColorDialog,
        setOpenColorDialog,
        currentDocument,
        setDocumentData,
        ProjectDetailsData,
        setProjectDetailsData,
        swmsAutocompleteAppVisibility,
        setSwmsAutocompleteAppVisibility,
        handleProjectDetailsChange,
        setRiskManagementScheduleData,
        highLevelRisksData,
        handleHighLevelRiskChange,
        riskManagementScheduleData,
        handleRiskManagementScheduleChange,
        setHighLevelRisksData,
        personsInvolvedCardData,
        setPersonsInvolvedCardData,
        handlePersonsInvolvedCardChange,
        constructionPlantEquipmentData,
        setConstructionPlantEquipmentData,
        handleConstructionPlantEquipmentChange,
        legislativeListData,
        handleLegislativeListChange,
        setLegislativeListData,
        ppeCardData,
        setPPECardData,
        handlePPECardChange,
        setPeopleInvolvedData,
        permitsData,
        setPermitsData,
        handlePermitsChange,
        inductionSignOnData,
        setInductionSignOnData,
        handleInductionSignOnChange,
        peopleInvolvedData,
        handlePeopleInvolvedChange,
        formReviewerData,
        setFormReviewerData,
        handleFormReviewerChange,
        riskTablesData,
        setRiskTablesData,
        handleRiskTablesChange,
        saveFormData,
        loadGeneratedData,
        resetFormData,
        documentColor,
        setDocumentColor,
        documentFontColor,
        setDocumentFontColor,
        documentTitle,
        setDocumentTitle,
        formMetadata,
        setFormMetadata,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};
