import React, { useState, useEffect, useContext } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  CardContent,
  Grid,
  TextField,
  Card,
  Box,
  InputLabel,
  TextareaAutosize,
  IconButton,
  Button,
  Popover,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Wrench } from "lucide-react";
import "@fontsource/roboto";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import { FormContext } from "../../context/FormContext";

const ConstructionPlantEquipment = ({ color, q7, fontColor }) => {
  const { constructionPlantEquipmentData, handleConstructionPlantEquipmentChange, documentColor } = useContext(FormContext);
  
  const [anchorEl, setAnchorEl] = useState(null);
  const [showDeleteIcons, setShowDeleteIcons] = useState(false);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (q7 && q7.length > 0) {
      console.log('inside if')
      const updatedSections = q7.map((item, index) => ({
        id: index + 1,
        number: item.Number || "",
        plant_and_equipment: Array.isArray(item["Required Plant and Equipment"])
          ? item["Required Plant and Equipment"]
          : [item["Required Plant and Equipment"] || ""],
        safety_consideration: Array.isArray(item["Safety Considerations"])
          ? item["Safety Considerations"]
          : item["Safety Considerations"]?.split('\n')
              .map(item => item.trim())
              .filter(item => item !== '')
              .map(item => item.startsWith('- ') ? item.substring(2) : item) || [],
        standards_and_regulations: Array.isArray(item["Relevant Standards and Regulations"])
          ? item["Relevant Standards and Regulations"]
          : item["Relevant Standards and Regulations"]?.split('\n')
              .map(item => item.trim())
              .filter(item => item !== '')
              .map(item => item.startsWith('- ') ? item.substring(2) : item) || [],
      }));

      handleConstructionPlantEquipmentChange(updatedSections);
    } else if (constructionPlantEquipmentData.length === 0) {
      console.log('inside else if')
      handleConstructionPlantEquipmentChange([{
        id: 0,
        number: "",
        plant_and_equipment: [""],
        safety_consideration: [],
        standards_and_regulations: [],
      }]);
    }
  }, [q7, handleConstructionPlantEquipmentChange]);

  const handlePopoverClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleAccordionChange = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  const handleAddSection = () => {
    const newSection = {
      id: constructionPlantEquipmentData.length,
      number: "",
      plant_and_equipment: [""],
      safety_consideration: [],
      standards_and_regulations: [],
    };
    handleConstructionPlantEquipmentChange([...constructionPlantEquipmentData, newSection]);
    handlePopoverClose();
  };

  const handleDeleteSection = (id) => {
    if (constructionPlantEquipmentData.length > 1) {
      const updatedSections = constructionPlantEquipmentData.filter((section) => section.id !== id);
      handleConstructionPlantEquipmentChange(updatedSections);
    }
  };

  const handleSectionChange = (id, e) => {
    const { name, value } = e.target;
    const updatedSections = constructionPlantEquipmentData.map((section) =>
      section.id === id
        ? {
            ...section,
            [name]: name === 'plant_and_equipment'
              ? [value]
              : name === 'safety_consideration' || name === 'standards_and_regulations'
                ? value.split('\n')
                    .map(item => item.trim())
                    .filter(item => item !== '')
                    .map(item => item.startsWith('- ') ? item.substring(2) : item)
                : value
          }
        : section
    );
    handleConstructionPlantEquipmentChange(updatedSections);
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const open = Boolean(anchorEl);
  const id = open ? "popover-anchor" : undefined;

  return (
    <Card
      sx={{
        boxShadow: 3,
        border: `4px solid ${documentColor}`,
        backgroundColor: "white",
        opacity: 0.9,
      }}
    >
      <Accordion expanded={expanded} onChange={handleAccordionChange}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            backgroundColor: documentColor,
            display: "flex",
            alignItems: "center",
            height: "60px",
            "&.Mui-expanded": {
              height: "50px",
            },
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
            <IconButton
              aria-describedby={id}
              onClick={(e) => {
                if (expanded) {
                  stopPropagation(e);
                  handlePopoverClick(e);
                }
              }}
              sx={{ padding: 0, color: fontColor }}
            >
              <Wrench />
            </IconButton>
            <Typography
              variant="h6"
              sx={{
                ml: 1,
                fontFamily: "Roboto, sans-serif",
                fontWeight: "bold",
                color: fontColor
              }}
              onClick={(e) => {
                if (expanded) {
                  stopPropagation(e);
                  handlePopoverClick(e);
                }
              }}
            >
              Construction Plant and Equipment
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ bgcolor: "#E9EAEC", padding: 2 }}>
          <CardContent
            sx={{
              padding: 0,
              marginBottom: 0,
              "&:last-child": {
                paddingBottom: 0,
              },
            }}
          >
            <Grid container spacing={2}>
              {constructionPlantEquipmentData.map((section) => (
                <Grid item xs={12} md={4} key={section.id}>
                  <Box
                    sx={{
                      border: `2px solid ${documentColor}`,
                      padding: 2,
                      borderRadius: "3px",
                      height: '100%',
                      position: "relative",
                      bgcolor: "E9EAEC",
                    }}
                  >
                    {showDeleteIcons && constructionPlantEquipmentData.length > 1 && (
                      <IconButton
                        onClick={() => handleDeleteSection(section.id)}
                        sx={{
                          position: "absolute",
                          top: "4px",
                          right: "4px",
                          padding: "0",
                          color: "red",
                        }}
                      >
                        <CancelIcon sx={{ fontSize: "15px" }} />
                      </IconButton>
                    )}
                    <Grid container direction="column" spacing={2}>
                      <Grid item>
                        <InputLabel
                          htmlFor={`activity-${section.id}`}
                          sx={{
                            fontSize: "12px",
                            fontWeight: "bold",
                            color: "#424242",
                            fontFamily: "Roboto, sans-serif",
                          }}
                        >
                          Plant and Equipment Activity
                        </InputLabel>
                        <TextField
                          fullWidth
                          id={`plant-${section.id}`}
                          name="plant_and_equipment"
                          value={Array.isArray(section.plant_and_equipment) 
                            ? section.plant_and_equipment[0] || '' 
                            : section.plant_and_equipment || ''}
                          onChange={(e) => handleSectionChange(section.id, e)}
                          variant="outlined"
                          size="small"
                          sx={{
                            bgcolor: "white",
                            borderRadius: "5px",
                            fontFamily: "Roboto, sans-serif",
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <InputLabel
                          htmlFor={`safety-${section.id}`}
                          sx={{
                            fontSize: "12px",
                            fontWeight: "bold",
                            color: "#424242",
                            fontFamily: "Roboto, sans-serif",
                          }}
                        >
                          Safety Maintenance
                        </InputLabel>
                        <TextareaAutosize
                          id={`safety-${section.id}`}
                          name="safety_consideration"
                          value={Array.isArray(section.safety_consideration) 
                            ? section.safety_consideration.join('\n') 
                            : '\n'}
                          onChange={(e) => handleSectionChange(section.id, e)}
                          minRows={6}
                          style={{
                            minWidth: "98.7%",
                            maxWidth: "98.7%",
                            padding: "8px",
                            marginTop: "8px",
                            borderRadius: "5px",
                            borderColor: "grey",
                            fontFamily: "Roboto, sans-serif",
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <InputLabel
                          htmlFor={`standards-${section.id}`}
                          sx={{
                            fontSize: "12px",
                            fontWeight: "bold",
                            color: "#424242",
                            fontFamily: "Roboto, sans-serif",
                          }}
                        >
                          Relevant Standards and Regulations
                        </InputLabel>
                        <TextareaAutosize
                          id={`standards-${section.id}`}
                          name="standards_and_regulations"
                          value={Array.isArray(section.standards_and_regulations) 
                            ? section.standards_and_regulations.join('\n') 
                            : '\n'}
                          onChange={(e) => handleSectionChange(section.id, e)}
                          minRows={6}
                          style={{
                            minWidth: "98.7%",
                            maxWidth: "98.7%",
                            padding: "8px",
                            marginTop: "8px",
                            borderRadius: "5px",
                            borderColor: "grey",
                            fontFamily: "Roboto, sans-serif",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </AccordionDetails>
      </Accordion>

      <Popover
        id={id}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ p: 3, display: "flex", flexDirection: "column", gap: 2 }}>
          <IconButton
            aria-label="close"
            onClick={handlePopoverClose}
            size="small"
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: "grey.500",
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
          <FormControlLabel
            control={
              <Checkbox
                checked={showDeleteIcons}
                onChange={() => setShowDeleteIcons(!showDeleteIcons)}
                sx={{
                  color: documentColor,
                  "&.Mui-checked": {
                    color: documentColor,
                  },
                }}
              />
            }
            label="Edit Section"
          />
          <Button
            variant="contained"
            component="span"
            sx={{
              bgcolor: documentColor,
              fontFamily: "Roboto, sans-serif",
              color: "black",
              borderRadius: "3px",
              textTransform: "none",
              boxShadow: 3,
              "&:hover": {
                boxShadow: 4,
              },
              "&:active": {
                boxShadow: 2,
              },
            }}
            startIcon={<AddIcon />}
            onClick={handleAddSection}
          >
            Add Section
          </Button>
        </Box>
      </Popover>
    </Card>
  );
};

export default ConstructionPlantEquipment;